import axios from "axios";
const config = require('../config.js')
const ROOT_URL = config.DTA_API
// const ROOT_URL = 'http://localhost:54450'

export const crmGetContactEdit = (object) => {
    return axios.post(`${ROOT_URL}/crm/get/contact_edit`, object, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const crmGetUser = (token, object, callback) => {
    return axios.post(`${ROOT_URL}/crm/get/contract_list`, object, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const crmEdit = (token, object) => {
    return axios.post(`${ROOT_URL}/crm/edit`, object, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const crmGetUserListByCitizenID = (token, citizen_id) => {
    return axios.get(`${ROOT_URL}/crm/get/user_detail_list/` + citizen_id, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const crmGetFileList = (token, data, callback) => {
    return axios.get(`${ROOT_URL}/crm/file/list/` + data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getDDRegisterList = (data) => {
    return axios.post(`${ROOT_URL}/crm/dd/get_register`,data, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const getDDTransactionList = (data) => {
    return axios.post(`${ROOT_URL}/crm/dd/get_payment`,data, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const getDDTokenList = (data) => {
    return axios.post(`${ROOT_URL}/crm/dd/get_token`,data, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const getFileType = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/crm/get_file_type`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getFormDetail = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/user/get_contract_data`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const EditForm = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/crm/user/edit_form`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const crmGetScoreing = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/crm/user/get_scoreing`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const updateScoreing = (data, callback) => {
    return axios.post(`${ROOT_URL}/crm/user/update/scoreing`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const updateModifyForm = (data, callback) => {
    return axios.post(`${ROOT_URL}/crm/user/update/modifyform`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const getRefinaceList = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/refinance/list`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const sentLineMassage = (data, callback) => {
    return axios.post(`${ROOT_URL}/refinance/sent/list`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const EditNote = (data, callback) => {
    return axios.post(`${ROOT_URL}/refinance/edit`, data, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
