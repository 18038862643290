import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import Link from '@mui/material/Link';

export default function NestedList() {
    let menus = JSON.parse(localStorage.getItem('menus'));
    let permis = JSON.parse(localStorage.getItem('permis'));
    const [expandedMenuId, setExpandedMenuId] = React.useState(null);
    const hasPermission = (menu) => {
        const permisValue = permis.find(p => p.sub_menu_id === menu.menu_id)?.permis_value;
        return permisValue === 1;
    }

    const subMenusWithPermission = (parentMenuId) => {
        const value = menus.some(subMenu => subMenu.menu_type === 'sub' && subMenu.parent_menu_id === parentMenuId && hasPermission(subMenu));
        return value;
    }

    const handleMenuClick = (menuId) => {
        setExpandedMenuId((prevState) => (prevState === menuId ? null : menuId));
    };

    return (
        <>
           {menus.map((menu) => (
    menu.menu_type === 'main' && (
        <React.Fragment key={menu.menu_id}>
            {subMenusWithPermission(menu.menu_id) && (
                <List>
                    <ListItemButton 
                        onClick={() => handleMenuClick(menu.menu_id)}
                        sx={{
                            backgroundColor: expandedMenuId === menu.menu_id ? '#cbf2ee' : 'transparent',
                            '&:hover': {
                                backgroundColor: '#01c0ab',
                            },
                        }}
                    >
                        <ListItemIcon>
                            <StarIcon />
                        </ListItemIcon>
                        <ListItemText primary={menu.menu_name} />
                        {expandedMenuId === menu.menu_id ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={expandedMenuId === menu.menu_id} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {menus
                                .filter(subMenu => 
                                    subMenu.menu_type === 'sub' && 
                                    subMenu.parent_menu_id === menu.menu_id && 
                                    hasPermission(subMenu)
                                )
                                .map((subMenu) => (
                                    <Link href={subMenu.menu_uri} color="inherit" underline="none" key={subMenu.menu_id} id={subMenu.menu_name}>
                                        <ListItemButton sx={{ pl: 9,
                                            backgroundColor: expandedMenuId === subMenu.menu_id ? '#cbf2ee' : 'transparent',
                                            '&:hover': {
                                                backgroundColor: '#01c0ab',
                                            }, }}>
                                            <ListItemText primary={subMenu.menu_name} />
                                        </ListItemButton>
                                    </Link>
                                ))}
                        </List>
                    </Collapse>
                </List>
            )}
        </React.Fragment>
    )
))}

{/* เมนู Logout */}
<Link href="/login/nova" color="inherit" underline="none">
    <ListItemButton style={{ position: 'relative', bottom: 1, width: '100%' }}>
        <ListItemIcon>
            <StarIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
    </ListItemButton>
</Link>

        </>
    );
}
