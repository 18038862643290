exports.ROOT_URL = "https://master-api.nova.tfm.in.th"
exports.PROD_API_URL = "https://collector-api.nova.tfm.in.th" //recheck wait for deploy
exports.ORC_URL = "https://ocr-api.nova.tfm.in.th" //recheck wait for deploy
exports.FILE_API_URL = "https://fliemng-api.nova.tfm.in.th" //recheck wait for deploy
exports.NCB_URL = "https://ncb-api.nova.tfm.in.th" //recheck wait for deploy
exports.PICO_URL = "https://dta-api.staging.batmaster.io:40443" //recheck wait for deploy
exports.LOGIN_URL = "https://auth-api.nova.tfm.in.th" //recheck wait for deploy
exports.DD_URL = "https://payment-api.nova.tfm.in.th" 
exports.DC_URL = "https://payment-api.nova.tfm.in.th"
exports.DTA_API = "https://api-dta.nova.tfm.in.th"
exports.COMMITEE_URL = "https://committee.nova.tfm.in.th" //recheck wait for deploy
exports.MKT_URL = "https://api-dta.nova.tfm.in.th"

