import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const style_note = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
function ModalNote({ open_note, handleSetFilters }) {
    console.log(open_note)

    const [rows, setOpenNote] = useState()
    const [note, setNote] = useState()
    const [open, setOpen] = useState(open_note)

    const handleEdit = (row, status) => {

    }
      const handleClose = () => {
    setOpen(false);
  };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_note}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenNote(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="child-modal-title">เพิ่มหมายเหตุ</h2>
                    <TextField
                        label="หมายเหตุ"
                        multiline
                        minRows={3} 
                        maxRows={5} 
                        variant="outlined"
                        fullWidth
                        value={note} onChange={(e) => setNote(e.target.value)}
                    />
                    <br></br>
                    <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEdit('note')}>บันทึก</Button>
                </Box>
            </Modal>
        </>)
}
export default ModalNote;
