
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideBar from '../../page/dashboard'
import { confirmAlert } from 'react-confirm-alert';
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as CrmService from '../../services/crm_service';
// components
import Filter from '../../components/filter_2';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import ExcelExport from '../../reports/direct_debit_excel.js'
import TextField from '@mui/material/TextField';

import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
const style_note = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_data, setRowsData] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const has_permission = checkPermission(location.pathname, decoded);
    const [filters, setFilters] = React.useState([])
    const [popup_note, setOpenNote] = React.useState(false)
    const [note, setNote] = React.useState([])
    const [rows, setRow] = React.useState([]);
    const [selected_row, setSelectedRow] = React.useState([]);
    const [selected_data, setSelectedData] = React.useState([]);
    const [excel_data, setExcelData] = React.useState([])
    let org_name = localStorage.getItem('org_name')


    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()

    }, [filters, selected_data])
    const fetchData = () => {
        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": filters
        }
        CrmService.getRefinaceList(token, object)
            .then((res) => {
                let filteredArray = []

                filteredArray = res.data.data

                let data = []
                filteredArray.map((item, index) => (
                    data.push(
                        {
                            "id": index + 1,
                            ...item
                        }
                    )
                ));
                setRowsData(data);
            })
    }
    const filter = [
        {
            property: 'date_pass_refinance',
            name: 'วันที่ผ่านเกณฑ์',
            type: 'date',
        },
        {
            property: 'policy_no',
            name: 'เลขที่สัญญา',
            type: 'input',
        },
        {
            property: 'IDCNO',
            name: 'เลขบัตรประชาชน',
            type: "input",
        },
        {
            property: 'name',
            name: 'ชื่อ-นามสกุล',
            type: "input",
        },
        {
            property: 'phone',
            name: 'เบอร์โทรศัพท์',
            type: "input",
        },
        {
            property: 'policy_date',
            name: 'วันที่ทำสัญญา',
            type: "date",

        },
        {
            property: 'amount',
            name: 'ยอดกู้',
            type: "input",

        },
        {
            property: 'OSB',
            name: 'ต้นคงค้าง',
            type: "input",

        },
        {
            property: 'CUMINT',
            name: 'ดอกเบี้ยคงค้าง',
            type: "input",

        },
        {
            property: 'OTD',
            name: 'ภาระหนี้คงเหลือ',
            type: "input",

        },
        {
            property: 'budget_text',
            name: 'ชั้นหนี้',
            type: "select",
            option: ['เกิน 1 เดือน - 3 เดือน', 'ปกติ'],

        },
        {
            property: 'pay_no',
            name: 'ระยะเวลาการผ่อน',
            type: "input",

        },
        {
            property: 'PPP',
            name: 'ค่างวด',
            type: "input",

        },
        {
            property: 'last_pay',
            name: 'วันที่ชำระล่าสุด',
            type: "date",

        },



        {
            property: 'dd_bank',
            name: 'ธนาคารที่เข้าระบบ DD',
            type: "select",
            option: ['ธนาคารกสิกรไทย', 'ธนาคารกรุงเทพ', 'ธนาคารไทยพาณิชย์', 'ธนาคารกรุงไทย', 'ธนาคารกรุงศรี']
        },

        {
            property: 'outstanding_principal',
            name: 'ต้นคงค้าง',
            type: "input",
        },
        {
            property: 'accrued_interest',
            name: 'ดอกเบี้ยคงค้าง',
            type: "input",
        },
        {
            property: 'last_payment_date',
            name: 'วันที่ชำระล่าสุด',
            type: "date",
        },
        {
            property: 'installment_paid',
            name: 'งวดที่ชำระไป',
            type: "input",

        },
        {
            property: 'payment_remaining',
            name: 'งวดคงเหลือ',
            type: "input",

        },

        {
            property: 'budget',
            name: 'จำนวนงวดค้าง',
            type: "input",

        },
        {
            property: 'TRCCode',
            name: 'Lastest TRC Code',
            type: "select",
            option: ['FIRST PAY', 'NEW', 'ADJ INT', 'PAY IN']

        },
        {
            property: 'EN_Followup',
            name: 'พนักงานออกสัญญา',
            type: "select",
            option: ['ลูกค้าใหม่', 'ลูกค้าใหม่ NANO', 'ออนไลน์', 'Cls ย้ายมา mscw', 'MOU SCAN', 'TOP UP', 'ทบทวนขยายงวด']

        },
        {
            property: 'interest_rate',
            name: 'อัตราดอกเบี้ย',
            type: "input",
        },
        {
            property: 'policy_group',
            name: 'กลุ่มสัญญา',
            type: "select",
            option: ['ฝากบันทึก - AMC', 'ฝากบันทึก - BYC', 'ฝากบันทึก - Cash Krub', 'ลูกค้ารายย่อย']
        },
        {
            property: 'job_income',
            name: 'รายได้รวม',
            type: "input",

        },
        {
            property: 'net_balance',
            name: 'คงเหลือสุทธิ',
            type: "input",

        },
        {
            property: 'bad_ncb',
            name: 'ประวัติเสียกับ NCB',
            type: "input",

        },
        {
            property: 'company_score',
            name: 'score บริษัท',
            type: "input",


        },
        {
            property: 'dd_status',
            name: 'สถานะการเข้าระบบ dd',
            type: "select",
            option: ['เข้าร่วม', 'ไม่เข้าร่วม']

        },

    ];
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'date_pass_refinance',
            headerName: 'วันที่ผ่านเกณฑ์ Refinance',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'policy_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'IDCNO',
            headerName: 'เลขที่บัตรประชาชน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'ชื่อ-นามสกุล',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'phone',
            headerName: 'เบอร์โทรศัพท์',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'policy_date',
            headerName: 'วันที่ทำสัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: 'ยอดกู้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        {financial(cellValues.row.amount)}
                    </>
                );
            }
        },
        {
            field: 'OSB',
            headerName: 'ต้นคงค้าง',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        {financial(cellValues.row.OSB)}
                    </>
                );
            }
        },
        {
            field: 'CUMINT',
            headerName: 'ดอกเบี้ยคงค้าง',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'OTD',
            headerName: 'ภาระหนี้คงเหลือ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        {financial(cellValues.row.OTD)}
                    </>
                );
            }
        },
        {
            field: 'budget_text',
            headerName: 'ชั้นหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'pay_no',
            headerName: 'ระยะเวลาการผ่อน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'PPP',
            headerName: 'ค่างวด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        {financial(cellValues.row.PPP)}
                    </>
                );
            }
        },
        {
            field: 'last_pay',
            headerName: 'วันที่ชำระล่าสุด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'installment_paid',
            headerName: 'งวดที่ชำระไป',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'payment_remaining',
            headerName: 'งวดคงเหลือ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'budget',
            headerName: 'จำนวนงวดค้าง',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'TRCCode',
            headerName: 'Lastest TRC Code',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'EN_Followup',
            headerName: 'พนักงานออกสัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'interest_rate',
            headerName: 'อัตราดอกเบี้ย',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'policy_group',
            headerName: 'กลุ่มสัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'job_income',
            headerName: 'รายได้รวม',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        {financial(cellValues.row.job_income)}
                    </>
                );
            }
        },
        {
            field: 'net_balance',
            headerName: 'คงเหลือสุทธิ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.net_balance === '-') {
                    return (
                        <>ไม่มีข้อมูล</>
                    );
                } else {
                    return (
                        <>
                            {parseFloat(cellValues.row.net_balance).toFixed(2)}
                        </>
                    );
                }

            }
        },
        {
            field: 'bad_ncb',
            headerName: 'ประวัติเสียกับ NCB',
            width: 300,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.bad_ncb === '-') {
                    return (
                        <>ไม่มีข้อมูล</>
                    );
                } else {
                    return (
                        <>
                            {cellValues.row.bad_ncb}
                        </>
                    );
                }

            }
        },
        {
            field: 'company_score',
            headerName: 'score บริษัท',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.company_score === '-') {
                    return (
                        <>ไม่มีข้อมูล</>
                    );
                } else {
                    return (
                        <>
                            {parseFloat(cellValues.row.company_score).toFixed(2)}
                        </>
                    );
                }

            }
        },
        {
            field: 'dd_status',
            headerName: 'สถานะการเข้าระบบ dd',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.dd_status === 'เข้าร่วม') {
                    return (
                        <>เข้าร่วม</>
                    );
                } else {
                    return (
                        <>ไม่เข้าร่วม</>
                    );
                }
            }
        },
        {
            field: 'dd_bank',
            headerName: 'ธนาคาร',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.dd_status === 'เข้าร่วม') {
                    return (
                        <>{cellValues.row.dd_bank}</>
                    );
                } else {
                    return (
                        <>-</>
                    );
                }
            }
        },

        {
            field: 'percen_payment',
            headerName: '%การชำระเงินคืน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {

                return (
                    <>{cellValues.row.percen_payment}%</>
                );

            }
        },
        {
            field: 'note',
            headerName: 'Note',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'note_edit',
            headerName: '',
            width: 20,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={(event) => {
                            handleOpenEditNote(event, cellValues);
                        }} ><EditIcon color="primary" /></IconButton>
                    </>
                );
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <Button variant="contained" size='small' onClick={(event) => {
                            handleSelectOne(cellValues.row);
                        }}>ส่งข้อความ  <SendIcon fontSize='small' style={{ marginLeft: "10px" }}></SendIcon></Button>

                    </>
                );
            }
        },
        {
            field: 'sent_line_date',
            headerName: 'วันที่ส่งข้อความ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.sent_line_date === '') {
                    return (
                        <>-</>
                    );
                } else {
                    return (
                        <>{cellValues.row.sent_line_date}</>
                    );
                }
            }
        },
        {
            field: 'sent_line_status',
            headerName: 'สถานะส่ง ข้อความ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.sent_line_date === '') {
                    return (
                        <>-</>
                    );
                } else {
                    return (
                        <>{cellValues.row.sent_line_status}</>
                    );
                }
            }
        },
    ];
    const handleSelectOne = (selected_data) => {
        let data = []
        data.push(selected_data)
        setSelectedData(data)
        handleSendLine(data)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpenEditNote = (e, value) => {
        setRow(value.row)
        setNote(value.row.note)
        setOpenNote(true)
    }
    const handleEdit = (value) => {
        let body
        if (value === 'note') {
            body = {
                policy_no: rows.policy_no,
                note: note,
            }
        }
        CrmService.EditNote(body)
            .then((res) => {
                if (res.data.success) {
                    alert('บันทึกสำเร็จ')
                    setOpenNote(false)
                    fetchData()
                }
            })
    }
    const handleSelectedChange = async (selected_rows) => {
        setSelectedRow(selected_rows);
        let select = []
        for (let selected_id of selected_rows) {
            let selected_data = await rows_data.find((row) => row.id === selected_id)
            select.push(selected_data)
        }
        setSelectedData(select)
    }
    const handleSendLine = (data = null) => {
        if (selected_data.length === 0) {
            alert('กรุณาเลือกรายการที่จะส่งข้อความก่อนครับ')
        } else {
            confirmAlert({
                title: 'ยืนยันที่จะส่งข้อความ?',
                message: `คุณแน่ใจหรือที่จะจะส่งข้อความเชิญชวน Refinance`,
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            CrmService.sentLineMassage(data === null ? selected_data : data)
                                .then((res) => {
                                    alert('ส่งข้อความสำเร็จ')
                                    fetchData()
                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        }
    };
    const handleExportExcelButton = () => {
        const list_datas = [];
        rows_data.map(item => {
            list_datas.push({

                "วันที่ผ่านเกณฑ์ Refinance": item.date_pass_refinance,
                "เลขที่สัญญา ": item.policy_no,
                "เลขที่บัตรประชาชน": item.IDCNO,
                "ชื่อ-นามสกุล": item.name,
                "เบอร์โทรศัพท์": item.phone,
                "วันที่ทำสัญญา": item.policy_date,
                "ยอดกู้": item.amount,
                "ต้นคงค้าง": item.OSB,
                "ดอกเบี้ยคงค้าง": item.CUMINT,
                "ภาระหนี้คงเหลือ": item.OTD,
                "ค่างวดคงค้าง": item.budget,
                "ระยะเวลาการผ่อน": item.pay_no,
                "ค่างวด": item.PPP,
                "วันที่ชำระล่าสุด": item.last_pay,
                "งวดที่ชำระไป": item.installment_paid,
                "งวดคงเหลือ": item.payment_remaining,
                "ชั้นหนี้": item.budget_text,
                "TRCCode": item.TRCCode,
                "พนักงานออกสัญญา": item.EN_Followup,
                "อัตราดอกเบี้ย": item.interest_rate,
                "กลุ่มสัญญา": item.policy_group,
                "รายได้รวม": item.job_income,
                "คงเหลือสุทธิ": item.net_balance,
                "ประวัติเสียกับ NCB": item.bad_ncb,
                "score บริษัท": item.company_score,
                "product": item.product,
                "สถานะการเข้า DD": item.dd_status,
                "ธนาคาร": item.dd_bank,
                "เปอร์เซ็นการชำระเงิน": item.percen_payment,
                "วันที่ส่งข้อความ": item.sent_line_date === '' ? '-' : item.sent_line_date,
                "สถานะการส่งข้อความ": item.sent_line_date === '' ? '-' : item.sent_line_status,
                "หมายเหตุ": item.note

            })
        });

        setExcelData(list_datas);
        ExcelExport(list_datas, "Refinance");
    }
    return (
        <>
            {has_permission && (
                <div className='customer-list' style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Filter
                            list={filter}
                            handleSetFilters={setFilters}
                        />
                        <Grid container spacing={2} style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Grid item>
                                <Button variant="contained" color="success" style={{ margin: "10px" }}
                                    onClick={() => handleExportExcelButton()}
                                > export</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid container spacing={2} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>

                                <Grid item>
                                    <Button variant="contained" size='large'
                                        onClick={() => handleSendLine()}
                                    >
                                        ส่งข้อความ<SendIcon fontSize='small' style={{ marginLeft: "10px" }}></SendIcon>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_data}
                                columns={columns}
                                checkboxSelection
                                disableRowSelectionOnClick
                                selectionModel={selected_row}
                                onSelectionModelChange={handleSelectedChange}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )}
            <Modal
                open={popup_note}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_note}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenNote(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="child-modal-title">เพิ่มหมายเหตุ</h2>
                    <TextField
                        label="หมายเหตุ"
                        multiline
                        minRows={3} 
                        maxRows={5} 
                        variant="outlined"
                        fullWidth
                        value={note} onChange={(e) => setNote(e.target.value)}
                    />
                    <br></br>
                    <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEdit('note')}>บันทึก</Button>
                </Box>
            </Modal>
        </>
    )
}
function financial(n) {
    if (n == 0) {
        return "0"
    } else if (n === null || n === undefined || isNaN(n)) {
        return "0"
    } else {
        return parseFloat(n).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}