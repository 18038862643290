import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SideBar from '../dashboard.js';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { confirmAlert } from 'react-confirm-alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectCredit from '../../services/direct_credit.js';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { checkPermission, getDecodedToken } from '../../utils/permission_utils.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as LoginService from '../../services/login_service.js';
export default function App() {
  let token_org = localStorage.getItem('token_org')
  let token = localStorage.getItem('token')
  let org_name = localStorage.getItem('org_name')

  let decode;
  if (token === null) {
    window.location.href = '/login';
  } else {
    decode = jwt_decode(token);
  }
  const location = useLocation();
  const [rows_tran, setRowsTran] = useState([]);
  const [open, setOpen] = useState(true);
  const [selected_row, setSelectedRow] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [open_dialog_tranfer, setOpenDialogTranfer] = useState(false);
  const [pin, setPin] = useState('');
  const [pin_func, setPinFunc] = useState(1)
  const [transfer_to_GB, setTranseferToGB] = useState(0.0)
  const [trensfer_to_customer, setTransferToCustomer] = useState(0.0)
  const [waive_type, setWaiveType] = useState('NONE')
  const [tranfer_data, setTransferData] = useState()


  const hasPermission = checkPermission(location.pathname, decode);

  useEffect(() => {
    if (!hasPermission) {
      window.location.href = '/404';
    } else {
      loadReadyList();
    }
  }, []);

  const loadReadyList = async () => {
    setOpen(true);
    let res = await DirectCredit.getReadyTransfer(token_org)
    let filteredArray = []
    filteredArray = res.data.data
    const list_data = filteredArray;
    const transfer = [];
    list_data.map((item, index) => {
      let advancepaid_month1 = item.advancepaid_month1 === 'no' || item.advancepaid_month1 === '' ? 0 : item.advance_paid_1
      let advancepaid_month2 = item.advancepaid_month2 === 'no' || item.advancepaid_month2 === '' ? 0 : item.advance_paid_1
      let bank_name = item.direct_debit === 1 ? (item.bank_name === undefined ? '-' : item.bank_name) : (item.bank_name === undefined ? '-' : item.bank_name)
      let account_no = item.direct_debit === 1 ? (item.accountno === undefined ? '-' : item.accountno) : (item.accountno === undefined ? '-' : item.accountno)

      let gb_fee = (item.waive_type === "BUREAU" || item.waive_type === "BUREAU(NEW)" || item.waive_type === "NONE") ? 15 : 0
      if (
        item.contact_id === '1500001'
        || item.contact_id === '27751'
        || item.contact_id === '1424351'
        || item.contact_id === '1597601'
        || item.contact_id === '1546851'
        || item.contact_id === '1662501'
        || item.contact_id === '1677251'
        || item.contact_id === '1547501'
        || item.contact_id === '1662551'
        || item.contact_id === '1616451'
      ) {
        gb_fee = 0
      }
      let bureau_fee = (item.waive_type === "NONE" || item.waive_type === "FEE" || item.waive_type === "FEE(NEW)") ? 13 : 0
      transfer.push(
        {
          "id": index + 1,
          "policygroup": item.policygroup,
          "fullname": `${item.name}`,
          "contact_id": item.contact_id,
          "offer_limit": formatNumberWithCommas(item.offer_limit),
          "duty_fee": item.duty_fee,
          "bureau": bureau_fee,
          "gb_fee": gb_fee,
          "advancepaid_month1": formatNumberWithCommas(advancepaid_month1),
          "advancepaid_month2": formatNumberWithCommas(advancepaid_month2),
          "close_payment": formatNumberWithCommas(item.close_payment),
          "net_transfer2": item.net_transfer == 0 ? formatNumberWithCommas(0) : formatNumberWithCommas(parseFloat(item.net_transfer) + 15),
          "net_transfer": formatNumberWithCommas(item.net_transfer),
          "total_cut_off": formatNumberWithCommas(parseFloat(item.duty_fee) + bureau_fee + gb_fee + parseFloat(advancepaid_month1) + parseFloat(advancepaid_month2) + parseFloat(item.close_payment.replace(/,/g, ''))),
          "bank_name": bank_name,
          "account_no": account_no,
          "case_maker": item.case_maker === 'null' ? '-' : item.case_maker,
          "direct_debit": item.direct_debit === 1 ? 'เข้าร่วม' : 'ไม่เข้าร่วม',
          "create_date": item.create_date,
          'waive_type': item.waive_type,
          'waive_desc': item.waive_type === 'NONE' ? 'ไม่ยกเว้น' : 'ยกเว้น'
        }
      )
    });

    setRowsTran(transfer);
    setOpen(false);
  }

  const formatNumberWithCommas = (number) => {
    return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleSelectedChange = async (selected_rows) => {
    setSelectedRow(selected_rows);
    let total_transfer_for_GB = 0
    let total_transfer_for_customer = 0
    for (let selected_id of selected_rows) {
      // console.log(selected_id);
      let selected_data = await rows_tran.find((row) => row.id === selected_id)
      console.log(selected_data);
      total_transfer_for_GB += parseFloat(selected_data.net_transfer2.replace(/,/, ''))
      total_transfer_for_customer += parseFloat(selected_data.net_transfer.replace(/,/, ''))
    }
    // console.log(total_transfer_for_GB);
    // console.log(total_transfer_for_customer);
    // const selectedData = selected_row.map((newSelection) =>
    //   rows_tran.find((row) => row.id === newSelection[0])
    // );
    // console.log(selected_row);
    // console.log(newSelection[0]);
    // console.log(await rows_tran.find((row) => row.id === newSelection));
    // console.log(selectedData)
    // let total_transfer_for_GB = 0
    // let total_transfer_for_customer = 0
    // for (let item of selectedData) {
    //   total_transfer_for_GB += parseFloat(item.net_transfer2)
    //   total_transfer_for_customer += parseFloat(item.net_transfer)
    // }
    setTranseferToGB(total_transfer_for_GB)
    setTransferToCustomer(total_transfer_for_customer)
    // setSelectedRow(newSelection);

  };

  const handleSendButton = () => {
    confirmAlert({
      title: 'ยืนยันที่จะอัพเดทสถานะ?',
      message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
      buttons: [
        {
          label: 'ตกลง',
          onClick: () => {
            setOpenDialog(true);
            setPin('');
            setPinFunc(1)
          }
        },
        {
          label: 'ยกเลิก',
          onClick: () => {
            console.log("no")
          }
        }
      ],
      overlayClassName: "overlay-custom-class-name"
    })

  };

  const handleOptionChange = (e) => {
    const option = e.target.value
    let title = "ยืนยันที่จะ"
    let text = "คุณแน่ใจหรือไม่ว่าจะ"
    if (option !== 'NONE') {
      title = title + "ยกเว้น"
      switch (option) {
        case "BUREAU":
          text += "ยกเว้นค่าบูโร (ลูกค้า Refinnace)";
          break;
        case "FEE":
          text += "ยกเว้นค่าธรรมเนียมการโอน (ลูกค้า Refinnace)";
          break;
        case "BUREAU&FEE":
          text += "ยกเว้นค่าบูโรและค่าธรรมเนียมการโอน (ลูกค้า Refinnace)";
          break
        case "BUREAU(NEW)":
          text += "ยกเว้นค่าบูโร (ลูกค้าใหม่)";
          break;
        case "FEE(NEW)":
          text += "ยกเว้นค่าธรรมเนียมการโอน (ลูกค้าใหม่)";
          break;
        case "BUREAU&FEE(NEW)":
          text += "ยกเว้นค่าบูโรและค่าธรรมเนียมการโอน (ลูกค้าใหม่)";
          break
      }
    } else {
      title = title + "ยกเลิกการยกเว้น"
      text = "คุณแน่ใจหรือไม่ว่าจะยกเลิกการยกเว้น"
    }
    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'ตกลง',
          onClick: () => {
            setOpenDialog(true);
            setPin('');
            setWaiveType(option)
            setPinFunc(2)
          }
        },
        {
          label: 'ยกเลิก',
          onClick: () => {
            console.log("no")
          }
        }
      ],
      overlayClassName: "overlay-custom-class-name"
    })
  }

  const updateClosePayment = (new_type) => {
    let selectedData = selected_row.map((selectedIndex) =>
      ({ ...rows_tran.find((row) => row.id === selectedIndex) })
    );
    let type = ''
    selectedData.forEach((item) => {
      const old_type = item.waive_type;
      const typeMapping = {
        'NONE': { 'BUREAU': 13, 'FEE': 15, 'BUREAU&FEE': 28, 'BUREAU(NEW)': 13, 'FEE(NEW)': 15, 'BUREAU&FEE(NEW)': 28 },
        'BUREAU': { 'NONE': -13, 'FEE': 2, 'BUREAU&FEE': 15 },
        'FEE': { 'BUREAU': 2, 'NONE': -15, 'BUREAU&FEE': 13 },
        'BUREAU&FEE': { 'BUREAU': -15, 'FEE': -13, 'NONE': -17 },
        'BUREAU(NEW)': { 'NONE': -13, 'FEE(NEW)': -2, 'BUREAU&FEE(NEW)': 15 },
        'FEE(NEW)': { 'BUREAU(NEW)': 2, 'NONE': -15, 'BUREAU&FEE(NEW)': 13 },
        'BUREAU&FEE(NEW)': { 'BUREAU(NEW)': -15, 'FEE(NEW)': -13, 'NONE': -23 },
      };
      console.log('old : ', old_type)
      if (typeMapping.hasOwnProperty(old_type) && typeMapping[old_type].hasOwnProperty(new_type)) {
        const amountChange = typeMapping[old_type][new_type];
        console.log('AMOUNT : ', amountChange)
        if (new_type.endsWith('(NEW)') || old_type.endsWith('(NEW)')) {
          item.net_transfer = (parseFloat(item.net_transfer.replace(/,/g, '')) || 0) + amountChange;
          type = 'new'
        }
        else {
          item.close_payment = (parseFloat(item.close_payment.replace(/,/g, '')) || 0) + amountChange;
          type = 'old'
        }
        item.waive_type = new_type;
      }
    });
    console.log(selectedData);

    let payload = {
      "list_data": selectedData,
      "type": type
    };

    console.log(payload)

    DirectCredit.update_waive(payload, token)
      .then((res) => {
        console.log(res)
        window.location.href = window.location.href
      }).catch(error => {
        console.log(error)
      })
  };

  const handlePinSubmit = () => {
    const selectedData = selected_row.map((selectedIndex) =>
      rows_tran.find((row) => row.id === selectedIndex)
    );
    console.log(selectedData)
    setTransferData(selectedData)
    let object = {
      "list_data": selectedData,
      "status": 3,
      "user": {
        user_id: decode.user_id,
        pin: pin
      }
    }
    DirectCredit.checkPIN(object, token_org)
      .then((res) => {
        if (res.data.success) {
          alert("PIN ถูกต้อง");
          setOpenDialog(false);
          // setOpenDialogTranfer(true);

          if (pin_func === 1) {
            setOpenDialogTranfer(true);
            DirectCredit.transfer(object, token)
              .then((res) => {
                console.log(res.data.data)
                setTransferData(res.data.data)
              })
          } else if (pin_func === 2) {
            updateClosePayment(waive_type)
          }
        } else {
          alert("PIN ไม่ถูกต้อง");
        }
      })

  };
  const handleClosePopup = () => {
    setOpenDialogTranfer(false)
    window.location.reload()
  }

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      hide: true
    },
    {
      field: 'No',
      headerName: 'No.',
      width: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

    },
    {
      field: 'create_date',
      headerName: 'วันที่ปล่อยสินเชื่อ',
      width: 125,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {format(new Date(params.value), 'dd/MM/yyyy')}
        </div>
      )

    },
    {
      field: 'contact_id',
      headerName: 'contact id',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      hide: true
    },
    {
      field: 'policygroup',
      headerName: 'ประเภทลูกค้า',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'fullname',
      headerName: 'ชื่อ-นามสกุล',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'offer_limit',
      headerName: 'ยอดอนุมัติ',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'net_transfer',
      headerName: 'ยอดโอนสุทธิให้ลูกค้า (บาท)',
      width: 175,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },

    {
      field: 'net_transfer2',
      headerName: 'ยอดโอนสุทธิให้ GB (บาท)',
      width: 175,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'bank_name',
      headerName: 'ธนาคาร',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'account_no',
      headerName: 'เลขบัญชี',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'direct_debit',
      headerName: 'เข้าร่วม Direct Debit',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'duty_fee',
      headerName: 'ค่าอากร',
      width: 75,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'bureau',
      headerName: 'ค่าตรวจบูโร',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'gb_fee',
      headerName: 'ค่าธรรมเนียม 1',
      width: 125,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'advancepaid_month1',
      headerName: 'ค่างวดล่วงหน้าเดือน 1',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'advancepaid_month2',
      headerName: 'ค่างวดล่วงหน้าเดือน 2',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'close_payment',
      headerName: 'ยอดชำระเพื่อปิดบัญชีเดิม',
      width: 175,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'waive_desc',
      headerName: 'เว้นค่าธรรมเนียม',
      width: 175,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'total_cut_off',
      headerName: 'ยอดรวมที่หัก',
      width: 175,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value !== 'null' ? params.value : 0}
        </div>
      ),
    },
    {
      field: 'case_maker',
      headerName: 'รายชื่อตัวแทน',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
  ]

  const columns_tranfer = [
    {
      field: 'id',
      headerName: 'id',
      width: 1,
      headerAlign: 'center',
      align: 'center',
      hide: true
    },
    {
      field: 'No',
      headerName: 'No.',
      width: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

    },
    {
      field: 'fullname',
      headerName: 'Name',
      width: 250,
      headerAlign: 'center',
      align: 'center',


    },
    {
      field: 'status',
      headerName: 'status',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {params.value === undefined ? "กำลังโอน" : (params.value === 'success' ? <div style={{ color: 'green' }}>Success</div> : <div style={{ color: 'red' }}>Fail</div>)}
        </div>
      ),
    },
    {
      field: 'date',
      headerName: 'วันที่ดำเนินการ',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'amount',
      headerName: 'จำนวนเงิน',
      width: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'referenceNo',
      headerName: 'referenceNo',
      width: 400,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'result_message',
      headerName: 'massage',
      width: 400,
      headerAlign: 'center',
      align: 'center',
    },
  ]



  return (
    <>
      {hasPermission && (
        <div className='customer-list' style={{ margin: '2rem' }}>
          <SideBar />
          <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
            <Grid item xs>
              <Typography variant='h4' style={{ fontWeight: '' }}>Finance 2 Transfer List</Typography>
            </Grid>
          </Grid>
          <Paper elevation={3}>
            <Grid container spacing={2} style={{ padding: '1rem' }}>
              <Grid container spacing={2} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                {/* <Grid item>
                  <Typography variant='body' style={{ fontWeight: '' }}>จำนวนเงินที่โอนไปยังลูกค้าทั้งหมด {formatNumberWithCommas(trensfer_to_customer)}</Typography>
                </Grid> */}
                <Grid item>
                  <Typography variant='body' style={{ fontWeight: '' }}>จำนวนเงินที่โอนไปยัง GB ทั้งหมด {formatNumberWithCommas(transfer_to_GB)}</Typography>
                </Grid>
                <Grid item>
                  <Button variant="contained" size='large'
                    onClick={() => handleSendButton()}
                  >
                    ยืนยัน
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {selected_row.length > 0 && (<Grid container spacing={2} style={{ padding: '1rem' }} >
              <Grid container spacing={2} style={{ paddingLeft: '30px', justifyContent: 'flex-start' }}>
                {/* <Grid item>
                  <Typography variant='body' style={{ fontWeight: '' }}>จำนวนเงินที่โอนทั้งหมด {formatNumberWithCommas(transfer_to_GB)}</Typography>
                </Grid> */}
                <Grid item>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Option</InputLabel>
                    <Select
                      style={{ width: 300 }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      //  value={}
                      label="Option"
                      // size='small'
                      onChange={(event) => handleOptionChange(event)}
                    >
                      <MenuItem value='NONE'>ไม่มี</MenuItem>
                      <MenuItem value='BUREAU' >ยกเว้นค่าบูโร (ลูกค้า Refinance)</MenuItem>
                      <MenuItem value='FEE' >ยกเว้นค่าธรรมเนียมการโอน (ลูกค้า Refinance)</MenuItem>
                      <MenuItem value='BUREAU&FEE'>ยกเว้นค่าบูโรและค่าธรรมเนียมการโอน (ลูกค้า Refinance)</MenuItem>
                      <MenuItem value='BUREAU(NEW)'>ยกเว้นค่าบูโร (ลูกค้าใหม่)</MenuItem>
                      <MenuItem value='FEE(NEW)'>ยกเว้นค่าธรรมเนียม (ลูกค้าใหม่)</MenuItem>
                      <MenuItem value='BUREAU&FEE(NEW)'>ยกเว้นบูโรและค่าธรรมเนียมการโอน (ลูกค้าใหม่)</MenuItem>
                    </Select>
                  </FormControl>

                </Grid>
              </Grid>
            </Grid>)}
            {open && ( // Render loading indicator when open is true
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Box
              sx={{
                height: 630,
                width: '100%',
              }}
            >
              <DataGrid
                rows={rows_tran}
                columns={columns}
                checkboxSelection
                disableRowSelectionOnClick
                selectionModel={selected_row}
                onSelectionModelChange={handleSelectedChange}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1.5,
                  },
                }}
              />
              {open && ( // Render loading indicator when open is true
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </Box>
          </Paper>
          {/* PIN Entry Dialog */}
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>Enter 4-Digit PIN</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter your 4-digit PIN to proceed.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="PIN"
                type="password"
                fullWidth
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                inputProps={{
                  maxLength: 4,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                ยกเลิก
              </Button>
              <Button onClick={handlePinSubmit} color="primary">
                ยืนยัน
              </Button>
            </DialogActions>
          </Dialog>
          {/* PIN Tranfer Dialog */}
          <Dialog open={open_dialog_tranfer} onClose={() => setOpenDialogTranfer(false)} fullWidth
            maxWidth="lg">
            <DialogTitle>สถานะการโอนเงิน</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  height: 630,
                  width: '100%',
                }}
              >
                <DataGrid
                  rows={tranfer_data}
                  columns={columns_tranfer}
                  disableRowSelectionOnClick
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: 1.5,
                    },
                  }}
                />
                {open && ( // Render loading indicator when open is true
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClosePopup()} color="primary">
                ปิด
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}