import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as userHttpServices from '../../services/login_service';
import NovaLogo from '../../../src/img/NV_Cash_Logo.png';
import TFMLogo from '../../../src/img/TFM_sign2.png';

import jwt_decode from "jwt-decode";
const theme = createTheme();

export default function SignIn() {
    localStorage.clear();
    const [org, setOrg] = React.useState(3);
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')

    let access_token
    const handleSubmit = (event) => {
        event.preventDefault();
        let object = {
            "org_id": 3,
            "username": username,
            "password": password
        }
        userHttpServices.TFMLogin(object)
            .then((res) => {

                if (res.data.response.status) {
                    access_token = jwt_decode(res.data.data[0].accessToken)
                    let object

                    if (access_token.old_user_id === null) {
                        object = {
                            "Username": 'admin',
                            "Password": "password",
                            "user_id": 1,
                            "org_id": 3
                        }
                        // object = {
                        //     "Username": 'ponlasin.p',
                        //     "Password": "ponlasin",
                        //     "user_id": 19,
                        //     "org_id": 2
                        // }
                    } else {
                        object = {
                            "Username": username,
                            "Password": password,
                            "user_id": access_token.old_user_id,
                            "org_id": 3
                        }
                    }
                    userHttpServices.LoginOrg(object)
                        .then((res) => {
                            let token_org = res.data.data[0].accessToken
                            let role = res.data.data[0].role_id
                            localStorage.setItem('token_org', token_org);
                            let permiss = access_token.permis_list.find(permis => permis.permis_value === 1);
                            let first_mnu = access_token.menu_list.find(menu => menu.menu_id === permiss.sub_menu_id && menu.access === 1);
                   
                            window.location.href = first_mnu.menu_uri
                        })
                        localStorage.setItem('org_name', 'NOVACAPITAL_CASHKRUB')
                        localStorage.setItem('menus', JSON.stringify(access_token.menu_list))
                        localStorage.setItem('token', res.data.data[0].accessToken)
                        localStorage.setItem('permis', JSON.stringify(access_token.permis_list))
                }
            })
            .catch((err) => {
                console.log(err)
                if (org === "") {
                    alert('Please Select Organization')
                } else {
                    alert(' Username OR Password is incorrect  OR Change Organization')
                }
            })
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" sx={{ width: '500px' }} style={{ marginTop: '6rem' }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <img fullwidth="true"  src={TFMLogo} alt='' />
                    <Typography component="h1" variant="h5" marginLeft={20}>
                        <i>Back Office</i>
                    </Typography>
                    <Typography component="h1" variant="h5" marginTop={2}>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <br />
                        <Button
                            id="button_login"
                            name="button_login"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2, mb: 2,
                                backgroundColor: 'rgb(1 192 171)', // สีพื้นหลัง
                                color: 'white', // สีข้อความ
                                '&:hover': {
                                  backgroundColor: '#4d4d4d', // สีพื้นหลังเมื่อ hover
                                },
                              }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}