import axios from "axios";
import jwt_decode from "jwt-decode";

const config = require('../config.js')

const NCB_URL = config.NCB_URL;
export const getContactID = (object, token, callback) => {
    return axios.get(`${NCB_URL}/hubspot/contacts/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
