
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import SideBar from '../../page/dashboard'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectDebit from '../../services/direct_debit.js';
// components
import Filter from '../../components/filter_2.js';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import * as CrmService from '../../services/crm_service';
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import {  GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import ExcelExport from '../../reports/direct_debit_excel.js'
import TextField from '@mui/material/TextField';

const style_note = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_data, setRowsData] = React.useState(true);
    const [popup_note, setOpenNote] = React.useState(false)
    const [note, setNote] = React.useState([])
    const [rows, setRow] = React.useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const has_permission = checkPermission(location.pathname, decoded);
    const [filters, setFilters] = React.useState([])
    const [excel_data, setExcelData] = React.useState([])
    let org_name = localStorage.getItem('org_name')

    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()
    }, [filters])
    const fetchData = () => {
        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": filters
        }
        CrmService.crmGetUser(token, object)
            .then((res) => {
                let filteredArray =[]
                     filteredArray = res.data.data
                let data = []
                filteredArray.map((item, index) => (
                    data.push(
                        {
                            "id": index + 1,
                            ...item
                        }
                    )
                ));
                setRowsData(data);
            })
    }
    const handleClose = () => {
        setOpen(false);
    };
    //filter
    const filter = [
        // {
        //     property: 'brand',
        //     name: 'Brand',
        //     type: 'select',
        //     option: ['MSCW', 'CASHKRUB', 'MOU_GSC', 'Pico Thai'],
        // },
        {
            property: 'product',
            name: 'Product',
            type: 'select',
            option: ['รายย่อยปกติ', 'รายย่อยปกติ (ไม่ตรวจ NCB)', 'สินเชื่อรวมหนี้', 'สินเชื่อ MOU', 'สินเชื่อนาโนไฟแนนซ์'],
        },
        {
            property: 'contract_no',
            name: 'เลขที่สัญญา',
            type: 'input',
        },
        {
            property: 'name',
            name: 'ชื่อ-นามสกุล',
            type: 'input',

        },
        {
            property: 'citizen_id',
            name: 'เลขที่บัตรประชาชน',
            type: 'input',

        },
        {
            property: 'phone_number',
            name: 'เบอร์โทรศัพท์',
            type: 'input',

        },
        {
            property: 'dd_resister',
            name: 'สถานะเข้าระบบ DD ',
            type: 'select',
            option: ['เข้าร่วม', 'ไม่เข้าร่วม(ไม่มีรหัสตัดเงิน)']
        },
        {
            property: 'limit',
            name: 'วงเงิน/งวด/ผ่อน',
            type: 'input'
        },
        {
            property: 'income',
            name: 'รายได้',
            type: 'input'

        },
        {
            property: 'net_balance',
            name: 'คงเหลือสุทธิ',
            type: 'input'

        },
        {
            property: 'ncb_score',
            name: 'คะแนน NCB',
            type: 'input'
        },
        {
            property: 'months_without_loss',
            name: 'จำนวนเดือนที่ไม่เสีย',
            type: 'input'

        },
        {
            property: 'age_acc_active',
            name: 'อายุบัญชีที่ active',
            type: 'input'
        },
        {
            property: 'outstanding_debt',
            name: 'ภาระหนี้คงเหลือ',
            type: 'input'
        },
        {
            property: 'home_acc',
            name: 'บัญชีบ้าน',
            type: 'input'
        },
        {
            property: 'car_acc',
            name: 'บัญชีรถ',
            type: 'input'
        },
        {
            property: 'bureau_status',
            name: 'สถานะบูโร',
            type: 'select',
            option: ['ค้างเกิน 180 วัน และปิดบัญชี', 'โดนอายัติเงินเดือน/โดนพิพากษา', 'บุคคลล้มละลาย', 'สะดุดค่าบ้าน-รถ 2 งวดขึ้นไป', 'ค้างเกิน 90 วัน และกลับมาชำระปกติ', 'TDR', 'ค้างไม่เกิน 90 วัน, เพิ่งแก้ไขภายใน 1 ไตรมาส', 'ค้างไม่เกิน 90 วัน, แก้ไขแล้วเกิน 1 ไตรมาส', 'ไม่ค้าง', 'ไม่มีไฟล์ NCB'],
        },
        {
            property: 'debt_class',
            name: 'ชั้นหนี้',
            type: "select",
            option: ['เกิน 12 เดือน', 'เกิน 6 เดือน - 12 เดือน', 'เกิน 3 เดือน - 6 เดือน', 'เกิน 1 เดือน - 3 เดือน', 'ปกติ'],

        },
        {
            property: 'company_score',
            name: 'score บริษัท',
            type: 'input'
        },
        {
            property: 'statement_score',
            name: 'score STM',
            type: 'input'
        },
        {
            property: 'case_maker',
            name: 'ผู้จัดทำ',
            type: "select",
            option: ['ศิระดา', 'อนงค์นาถ', 'ปฐมาวดี', 'รมย์นรินทร์','มานิตา'],

        }
    ];
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'brand',
            headerName: 'Brand',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'product',
            headerName: 'Product',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        // {
        //     field: 'create_date',
        //     headerName: 'วันที่สมัคร',
        //     width: 200,
        //     editable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        // },
        {
            field: 'name',
            headerName: 'ชื่อ-นามสกุล',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'citizen_id',
            headerName: 'เลขที่บัตรประชาชน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'phone_number',
            headerName: 'เบอร์โทรศัพท์',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'dd_resister',
            headerName: 'สถานะเข้าระบบ DD ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'all',
            headerName: 'วงเงิน/งวด/ผ่อน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'income',
            headerName: 'รายได้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'net_balance',
            headerName: 'คงเหลือสุทธิ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'ncb_score',
            headerName: 'คะแนน NCB',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'months_without_loss',
            headerName: 'จำนวนเดือนที่ไม่เสีย',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'age_acc_active',
            headerName: 'อายุบัญชีที่ active',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'outstanding_debt',
            headerName: 'ภาระหนี้คงเหลือ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'home_acc',
            headerName: 'บัญชีบ้าน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'car_acc',
            headerName: 'บัญชีรถ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'bureau_status',
            headerName: 'สถานะบูโร',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'debt_class',
            headerName: 'ชั้นหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'company_score',
            headerName: 'score บริษัท',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'statement_score',
            headerName: 'score STM',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'installment_ncb',
            headerName: 'ผ่อนหนี้ใน NCB',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'installment_paid',
            headerName: 'ชำระแล้ว (งวด)',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'case_maker',
            headerName: 'ผู้จัดทำ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'note_top_up',
            headerName: 'Note',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        }, {
            field: 'note',
            headerName: '',
            width: 20,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={(event) => {
                            handleOpenEditNote(event, cellValues);
                        }} ><EditIcon color="primary" /></IconButton>
                    </>
                );
            }

        }
    ];
    const handleOpenEditNote = (e, value) => {
        setRow(value.row)
        setNote(value.row.note_top_up)
        setOpenNote(true)
    }
    const handleEdit = (value) => {
        let body
        if (value === 'note') {
            body = {
                query: rows._id,
                note: note,
                note_type: "note_top_up"
            }
        }
        CrmService.crmEdit(token, body)
            .then((res) => {
                if (res.data.success) {
                    alert('บันทึกสำเร็จ')
                    setOpenNote(false)
                    fetchData()
                }
            })
    }
    const handleExportExcelButton = () => {
        const list_datas = [];
        rows_data.map(item => {
            list_datas.push({
                "Brand": item.brand,
                "Product": item.product,
                "เลขที่สัญญา": item.contract_no,
                "ชื่อ-นามสกุล": item.name,
                "เลขที่บัตรประชาชน": item.citizen_id,
                "เบอร์โทรศัพท์": item.phone_number,
                "สถานะเข้าระบบ DD": item.dd_resister,
                "ธนาคารที่เข้าระบบ DD": item.bank_name,
                "ชั้นหนี้": item.debt_class,
                "ต้นคงค้าง": item.outstanding_principal,
                "ดอกเบี้ยคงค้าง": item.accrued_interest,
                "วันที่ชำระล่าสุด": item.last_pay ==='1900-01-01'||item.last_pay ==='Invalid date' ? '-':item.last_pay,
                "score บริษัท": item.company_score,
                "สถานะดำเนินการ": '-',
                "กลุ่มสัญญา": item.policy_group,
                "ผู้ดูแล": item.care_taker,
                "วันที่เจราจาหนี้": '-',
                "Note": item.note_popose,
            })
        });

        setExcelData(list_datas);
        ExcelExport(list_datas, "crm_propose");
    }
    return (
        <>

            {has_permission && (
                <div className='customer-list' style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Filter
                            list={filter}
                            handleSetFilters={setFilters}
                        />
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )}
            <Modal
                open={popup_note}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_note}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenNote(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="child-modal-title">เพิ่มหมายเหตุ</h2>
                    <TextField
                        label="หมายเหตุ"
                        multiline
                        minRows={3} 
                        maxRows={5} 
                        variant="outlined"
                        fullWidth
                        value={note} onChange={(e) => setNote(e.target.value)}
                    />
                    <br></br>
                    <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEdit('note')}>บันทึก</Button>
                </Box>
            </Modal>
        </>
    )
}