import axios from "axios";
const config = require('../config.js')

const DD_URL = config.DD_URL
const ROOT_URL = config.ROOT_URL
// const DD_URL = "http://localhost:44446";

export const isDirectDebit = () => {
    return axios.get(`${DD_URL}/direct_debit/check`)
}
export const syncDataToDirectDebit = (obj) => {
    return axios.post(`${DD_URL}/pos/sync_data`, obj)
}
export const addContractDirectDebit = (token, obj) => {
    return axios.post(`${ROOT_URL}/user/approved`, obj, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}
export const getUser = (object, token, callback) => {
    return axios.get(`${DD_URL}/get_user`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getUserDetail = (object, token, callback) => {
    return axios.post(`${DD_URL}/user/get_detail`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const payMent = (object, token, callback) => {
    return axios.post(`${DD_URL}/gb/payment`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const manualPayment = (object, token, callback) => {
    return axios.post(`${DD_URL}/gb/payment`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const deduction = (object, token, callback) => {
    return axios.post(`${DD_URL}/gb/deduction`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getDeductionAutoList = (object, token, callback) => {
    return axios.get(`${DD_URL}/gb/get_deduction_auto_list`, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}
export const DeductionAutoDetail = (object, token, callback) => {
    return axios.post(`${DD_URL}/gb/deduction_auto_dtail`,object, {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
    })
}

export const addManualPayment = (object) => {
    console.log('object', object)
    return axios.post(`${DD_URL}/user_manual_payment`, object, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
export const closeDDContract = (object,token) => {
    return axios.post(`${DD_URL}/user_close_contract`, object, {
        headers: {
            'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + token
        },
    })
}
export const changeCutoffDate = (object,token) => {
    return axios.post(`${DD_URL}/user/change_cutoff_date`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token

        },
    })
}

export const editUserDetail = (object,token) => {
    console.log(token)
    return axios.post(`${DD_URL}/edit_user_detail`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getTransactionStatus = (object) => {
    return axios.post(DD_URL + '/get_transaction_status',object)
};
export const getTransactionExport = (object) => {
    return axios.post(DD_URL + '/get_transaction_export', object)
};
export const getUserManualPayment = (object) => {
    return axios.get(DD_URL + '/get/user_manual_payment', object)
};
export const updateManualPayment = (object,token) => {
    return axios.post(DD_URL + '/update_manual_payment', object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const userGBRegiser = (object,token) =>{
    return axios.post(DD_URL +'/user/register', object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getGBLog = () => {
    return axios.get(DD_URL + '/get/log')
}
export const getTotalAmount = (object) => {
    return axios.post(DD_URL + '/dd/totalAmount', object)
};

export const getSuccessAmount = (object) => {
    return axios.post(DD_URL + '/dd/successAmount', object)
};

export const getUnsuccessAmount = (object) => {
    return axios.post(DD_URL + '/dd/unsuccessAmount', object)
};
export const getUserRegisDirectDebit = () => {
    return axios.get(DD_URL + '/dd/get_user_register')
};
