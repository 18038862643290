import axios from "axios";
import jwt_decode from "jwt-decode";
// import config from '../config.js'
const config = require('../config.js')

const NCB_URL = config.NCB_URL;
var token = localStorage.getItem('token')

export const getCollectorExport = (token, body) => {
    return axios.post(`${NCB_URL}/hubspot/contacts/export2`, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}