import * as React from 'react';
import Box from '@mui/material/Box';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as LoginService from '../../services/login_service.js';

import { Typography, Grid, Button, TextField, Paper, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
// components
import * as DirectDebit from '../../services/direct_debit.js';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import ExcelExport from '../../reports/direct_debit_excel.js'

export default function App() {
    let token = localStorage.getItem('token')
    let decoded
    let org_name = localStorage.getItem('org_name')
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const location = useLocation();
    const hasPermission = checkPermission(location.pathname, decoded);
    // backdrop

    const [open, setOpen] = React.useState(true);
    const [rows_tran, setRowsTran] = React.useState([]);
    const [start_date, setStartDate] = React.useState(null);
    const [end_date, setEndDate] = React.useState(null)
    const [policy_group, setPolicyGroup] = React.useState('-')
    const [export_data, setExportData] = React.useState([])

    React.useEffect(() => {
        if (!hasPermission) {
            window.location.href = '/404'
        }
        loadAwaitList()
    }, [])
    const loadAwaitList = async () => {
        setOpen(true);
        let obj = {
            status: 2,
            start_date: start_date,
            end_date: end_date,
            policy_group: policy_group
        }
        try {
        DirectDebit.getTransactionStatus(obj, token)
            .then((res) => {
                console.log(res)
                if (res.data.success) {
                    setRowsTran(res.data.data)
                    let export_data = []
                    res.data.data.forEach((data, index) => {
                        let transaction_date = data.transaction_date == null ? "-" : data.transaction_date.split("T")[0]
                        let transaction_time = data.transaction_date == null ? "-" : new Date(data.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })
                        let name = data.name + ' ' + data.lastname
                        let row_data = {
                            "No": index + 1,
                            "เลขที่สัญญา": data.contract_no,
                            "กลุ่มสัญญา": data.policy_group,
                            "วันที่ตัด": transaction_date,
                            "เวลาที่ตัด": transaction_time,
                            "ชื่อ-สกุล": name,
                            "หมายเหตุ": data.resultMessage,
                            "Collector Name": '',
                            "วงเงินกู้": '',
                            "ยอดชำระ": '',
                            "ค่างวด": ''
                        };
                        export_data.push(row_data); 
                    });
                    setExportData(export_data)
                } else {
                    alert("The request was not successful.");
                }
            })

            // let res = await DirectDebit.getTransactionStatus(obj)
            // let user_brand = await LoginService.getAllBrandUser(obj)
            // const mergedArr = res.data.data.reduce((acc, obj1) => {
            //     const obj2 = user_brand.data.data.find(obj2 => String(obj2.contact_id) === obj1.contract_id)
            //     return [...acc, { ...obj1, ...obj2 }];
            // }, []);
            // let filteredArray = []
            // if (org_name === "NVCASH") {
            //     filteredArray = mergedArr.filter(item => item.contact_brand === "NVCASH");
            // } else {
            //     filteredArray = res.data.data
            // }
            // setRowsTran(filteredArray)
        } catch (error) {
            setRowsTran([])
        }
    }
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'policy_group',
            headerName: 'กลุ่มสัญญา',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'transaction_date',
            headerName: 'วันที่ตัด',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : params.row.transaction_date.split("T")[0]}`,
        },
        {
            field: 'col3',
            headerName: 'เวลาที่ตัด',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : new Date(params.row.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })}`,
        },
        {
            field: 'name',
            headerName: 'ชื่อ',
            width: 300,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'resultMessage',
            headerName: 'หมายเหตุ',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'col6',
            headerName: 'Collector Name',
            width: 300,
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'col7',
            headerName: 'วงเงินกู้',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'col8',
            headerName: 'ยอดชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'col9',
            headerName: 'ค่างวด',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        }
    ];
    const handleSearchButton = () => {

        if (!start_date || !end_date || end_date < start_date) {
            alert("วันที่ผิดโปรดเลือกวันที่ใหม่อีกครั้ง");
            return;
        }
        else {

            loadAwaitList();
        }
    }
    const handleExportExcelButton = () => {
        ExcelExport(export_data, "Direct Debit Unsuccess");

    }
    return (
        <>
            {hasPermission && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Pay Unsuccess Page</Typography>
                        </Grid>
                    </Grid>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={4} >
                        <Typography variant='h6' style={{ padding: '10px' }}>Unsuccess payment list</Typography>
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่เริ่มต้น"
                                        inputFormat="YYYY-MM-DD"
                                        value={start_date}
                                        onChange={(date) => setStartDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="ถึงวันที่"
                                        inputFormat='YYYY-MM-DD'
                                        value={end_date}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                        minDate={start_date}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ minWidth: 300 }} >
                                    <InputLabel>กลุ่มสัญญา</InputLabel>
                                    <Select
                                        name="policy_group"
                                        label="กลุ่มสัญญา"
                                        size='small'
                                        value={policy_group}
                                        onChange={(e) => setPolicyGroup(e.target.value)}
                                    >
                                        <MenuItem value={'-'}>ทั้งหมด</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - BYC'}>ฝากบันทึก - BYC</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - CLS'}>ฝากบันทึก - CLS</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - CLS1'}>ฝากบันทึก - CLS1</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - CLS2'}>ฝากบันทึก - CLS2</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ผ่อนสินค้า'}>ฝากบันทึก - ผ่อนสินค้า</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ลูกหนี้ MSCW'}>ฝากบันทึก - ลูกหนี้ MSCW</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ลูกหนี้ NP PICO'}>ฝากบันทึก - ลูกหนี้ NP PICO</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ลูกหนี้ พัฒนาสินทรัพย์บุคคล'}>ฝากบันทึก - ลูกหนี้ พัฒนาสินทรัพย์บุคคล</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ลูกหนี้ พิโกชลบุรี<'}>ฝากบันทึก - ลูกหนี้ พิโกชลบุรี</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - AMC'}>ฝากบันทึก - AMC</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว'}>ฝากบันทึก - ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - ฝากบันทึกลูกหนี้ MSCW'}>พิโกชลบุรี - ฝากบันทึกลูกหนี้ MSCW</MenuItem>
                                        <MenuItem value={'ฝากบันทึก CLS'}>ฝากบันทึก CLS</MenuItem>
                                        <MenuItem value={'ลูกค้ารายย่อย'}>ลูกค้ารายย่อย</MenuItem>
                                        <MenuItem value={'ลูกค้ารายย่อย - MOU SCAN'}>ลูกค้ารายย่อย - MOU SCAN</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - Cash Krub'}>ฝากบันทึก - Cash Krub</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - Nova Capital'}>ฝากบันทึก - Nova Capital</MenuItem>
                                        <MenuItem value={'ฝากบันทึก - Easy Cap'}>ฝากบันทึก - Easy Cap</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' style={{ marginTop: 2 }}
                                    onClick={() => handleSearchButton()}
                                >
                                    ค้นหา
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" style={{ marginTop: 2 }}
                                    onClick={() => handleExportExcelButton()}
                                >
                                    EXPORT
                                </Button>
                            </Grid>

                        </Grid>
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                            }}
                        >
                            <DataGrid
                                rows={rows_tran}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )
            }
        </>
    )
}