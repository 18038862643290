import axios from "axios";
import jwt_decode from "jwt-decode";

const config = require('../config.js')

const ROOT_URL = config.NCB_URL;
let  ORG = "mscw";

export const detail = (uri, file_id, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/` + ORG + `/` + uri + `/` + file_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const summarize = (file_id, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/` + ORG + `/sum/by_contact_id/` + file_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const checkID = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/ncb_id/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const createUser = (object, token, callback) => {
    console.log(token)
    return axios.post(`${ROOT_URL}/user/create`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const insert = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/user/insert`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const del = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/delete/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const showUser = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};


export const detail1 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/pico/detail_1/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const detail2 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/pico/detail_2/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const detail3 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/pico/detail_3/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const detail4 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/pico/detail_4/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const addlog = (object) => {
    return axios.post(`${ROOT_URL}/add/log`, object, {})
}

export const ncbGetData = (contact_id) => {
    return axios.get(`${ROOT_URL}/user/get_data/mscw/table/${contact_id}`)
}
