import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans Thai, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#01c0ab',     // เปลี่ยนสีหลักเป็นสีที่ต้องการ (เช่น สีส้ม)
      contrastText: '#fff', // กำหนดสีข้อความสำหรับปุ่มที่ใช้ primary
    },
    secondary: {
      main: '#4caf50',      // เปลี่ยนสีรองลงมา (เช่น สีเขียว)
      contrastText: '#fff', // กำหนดสีข้อความสำหรับปุ่มที่ใช้ secondary
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10, // กำหนดขอบปุ่มโค้ง
          fontSize: '1rem', // กำหนดขนาดข้อความในปุ่ม
        },
        contained: {
          boxShadow: 'none', // กำหนดการแสดงเงา
          '&:hover': {
            backgroundColor: '#4d4d4d', // สีเมื่อ hover
          },
        },
      },
    },
  },
});

// ReactDOM.render(
//   <ThemeProvider theme={theme}>
//     <App />
//   </ThemeProvider>,
//   document.getElementById('root')
// );
root.render(
  <ThemeProvider theme={theme}>
      <App />
      </ThemeProvider>,
);

reportWebVitals();
