/* eslint-disable eqeqeq */
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Demo from '../components/listItems';
import jwt_decode from "jwt-decode";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MSCWImage from '../../src/assets/images/mscw-image-new-1406015597.jpg'
import NovaCKImage from '../../src/assets/images/logo_nvcash_cashkrub.png'
import PicoImage from '../../src/assets/images/Logo-Pico-เงินด่วน.png'


const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    let data = JSON.parse(localStorage.getItem('data'))
    var token = localStorage.getItem('token')
    var decoded = jwt_decode(token)
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClose2 = () => {
        console.log(decoded.org_id)
        if (decoded.org_id === 3) {
            window.location.href = '/login/nova'
        }else{
            window.location.href = '/login'

        }

    }
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose2}>Logout</MenuItem>
        </Menu>
    );

    var href = window.location.href
    var str = href.substring(href.lastIndexOf("/") + 1, href.length)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const checkOrgLogo = () => {
        if (decoded.org_id === 1) {
            return <img style={{ width: '200px' }} src={PicoImage} alt='' />
        } else if (decoded.org_id === 2) {
            return <img style={{ width: '200px' }} src={MSCWImage} alt='' />
        } else {
            return <img style={{ width: '200px' }} src={NovaCKImage} alt='' />
        }

    }
    const titleName = () => {
        if (str === 'contact_management' || str === 'collector_report' || str === 'contact_collector') {
            return 'Collection'
        }
        if (str === 'file_management') {
            return 'File Management'
        }

        if (str === 'document_tracking' || str === 'customer_pending' || str === 'user_status') {
            return 'Customer Document'
        }

        //direct-debit
        if (str === 'pay_success_export' || str === 'pay_unsuccess_page' || str === 'pay_success_page' || str === 'customer_details' || str === 'customer_list' || str === 'manual_process_page' || str === 'auto_partial') {
            return 'Direct Debit'
        }
        if (str === 'transfer_waiting' || str === 'transfer_ready' || str === 'transfer_success' || str === 'operator') {
            return 'Direct Credit'
        }
        if (str === 'view') {
            return 'Marketing'
        }
        if (str === 'Dashboard' || 'CRM') {
            return 'CRM'
        }
        else { return 'Dashboard' }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{ background: 'rgb(1 192 171)' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        id='button-open-menu'
                        name='button-open-menu'
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {titleName()}
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label=""
                            color="inherit"
                        >
                            <Badge badgeContent={0} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        {renderMenu}

                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}>
                <DrawerHeader>
                    {checkOrgLogo()}
                        < IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <Demo />
        </Drawer>

        </Box >
    );
}
