import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import SideBar from '../dashboard.js';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { confirmAlert } from 'react-confirm-alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as TackingService from '../../services/tacking_service.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function App() {
    let token = localStorage.getItem('token')
    let token_org = localStorage.getItem('token_org')
    let org_name = localStorage.getItem('org_name')

    let decode;
    if (token === null) {
        window.location.href = '/login';
    } else {
        decode = jwt_decode(token);
    }
    const [open, setOpen] = useState(false);
    const [open_popup, setopenPopup] = useState(false);
    const [rows_1, setRows1] = useState([]);
    const [rows_open, setRowsOpen] = useState(false);
    const [rows_2, setRows2] = useState([]);
    const [rows_3, setRows3] = useState([]);
    const [user_list, setUserList] = useState([]);
    const [columns1, setColumns1] = useState([{
        field: 'id',
        headerName: 'id',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        hide: true
    }]);
    const [columns2, setColumns2] = useState([{
        field: 'id',
        headerName: 'id',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        hide: true
    }]);
    const [columns3, setColumns3] = useState([{
        field: 'id',
        headerName: 'id',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        hide: true
    }]);
    const [date, setDate] = useState(null);
    const location = useLocation();

    // const has_permis = checkPermission(location.pathname, decode);
    const has_permis = true

    useEffect(() => {
        if (!has_permis) {
            window.location.href = '/404';
        }

    }, [columns1, rows_1])

    const loadAwaitList = () => {
        setOpen(true);
        let object = {
            date: date
        }
        TackingService.getMarketingDashBoard(object, token_org)
            .then((res) => {
                setOpen(true)
                res.data.data.not_pass_col.map((item, index) => {
                    if (item != 'id') {
                        columns1.push({
                            field: item,
                            headerName: item,
                            width: 200,
                            headerAlign: 'center',
                            align: 'center',
                            sortable: false,
                            renderCell: (params) => (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {params.value === 'null' || params.value === undefined ? "0" : params.value}
                                </div>
                            ),
                        })

                    }
                    setColumns1(columns1)
                })
                res.data.data.pass_col.map((item, index) => {
                    if (item != 'id') {
                        columns2.push({
                            field: item,
                            headerName: item,
                            width: 200,
                            headerAlign: 'center',
                            align: 'center',
                            sortable: false,
                            renderCell: (params) => (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {params.value === 'null' || params.value === undefined ? "0" : params.value}
                                </div>
                            ),
                        })
                    }
                    setColumns2(columns2)
                });
                res.data.data.pass_no_da_col.map((item, index) => {
                    if (item != 'id') {
                        if (item !== 'Brand' && item !== 'Total') {
                            columns3.push({
                                field: item,
                                headerName: item,
                                width: 200,
                                headerAlign: 'center',
                                align: 'center',
                                renderCell: (row) => (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {row.value === 'null' || row.value === undefined ? 0 : row.value}
                                    </div>
                                    // <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    //     {<div onClick={(e) => handleGetUser(e,row)} style={{color:"blue", textDecorationLine:"underline"}}>{row.value === 'null' || row.value === undefined ? 0 : row.value}</div>}

                                    // </div>
                                    // <a  onClick={(e) => setopenPopup(params)}>
                                    //     {params.value}
                                    // </a>
                                ),
                            })
                        } else {
                            columns3.push({
                                field: item,
                                headerName: item,
                                width: 200,
                                headerAlign: 'center',
                                align: 'center',
                                sortable: false,
                                renderCell: (params) => (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {params.value === 'null' || params.value === undefined ? "0" : params.value}
                                    </div>
                                ),
                            })
                        }

                    }
                    setColumns3(columns3)
                });
            //     if (org_name === "NVCASH") {
            //        let not_pass  = res.data.data.not_pass.filter(item => item.Brand === "NVCASH");
            //        let pass  = res.data.data.pass.filter(item => item.Brand === "NVCASH");
            //        let pass_no_da  = res.data.data.pass_no_da.filter(item => item.Brand === "NVCASH");
            //         setRows1(not_pass)
            //         setRows2(pass)
            //         setRows3(pass_no_da)
            //    } else {
                setRows1(res.data.data.not_pass)
                setRows2(res.data.data.pass)
                setRows3(res.data.data.pass_no_da)
            //    }
               
                setRowsOpen(true)
                setOpen(false)
            })
    }

    const handleSearchButton = () => {
        loadAwaitList();
    }

    const columns_user = [
        {
            field: 'id',
            headerName: 'id',
            width: 1,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'ลำดับ',
            width: 50,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

        },
        {
            field: 'fullname',
            headerName: 'ชื่อ-นามสกุล',
            width: 250,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'Brand',
            headerName: 'Brand',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'MarketingChannel',
            headerName: 'ช่องทางการตลาด',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'PhoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        }]
    const handleGetUser = (e, value) => {
        let object = {
            date: date,
            mk: value.field,
            brand: value.row.Brand,
            type: "no_da"
        }
        TackingService.getMarketingDashBoardUserList(object, token_org)
            .then((res) => {

                const list_data = res.data.data;
                const data = [];
                list_data.map((item, index) => {
                    data.push(
                        {
                            "id": index + 1,
                            "fullname": `${item.Name}  ${item.Surname}`,
                            "PhoneNumber": `${item.PhoneNumber}`,
                            "Brand": `${item.Band}`,
                            "MarketingChannel": `${item.MarketingChannel}`
                        }
                    )
                })
                setUserList(data)
            })
        setopenPopup(true)

    }
    return (
        <>

            {has_permis && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Marketing Dashboard</Typography>
                        </Grid>
                    </Grid>
                    <Paper elevation={3} style={{ padding: 50 }}>
                        <Grid container spacing={2} style={{ padding: '1rem', marginLeft: 20 }}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่"
                                        inputFormat="YYYY-MM-DD"
                                        value={date}
                                        onChange={(date) => setDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>

                            <Grid item spacing={2} style={{}}>
                                <Button variant='contained'
                                    onClick={() => handleSearchButton()}
                                >
                                    เรียกดูรายงาน
                                </Button>
                            </Grid>

                        </Grid>
                        {open && ( // Render loading indicator when open is true
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}
                        {rows_open && (
                            <Box
                                sx={{
                                    height: "100%",
                                    width: '100%',
                                }}
                            >

                                <Grid item xs>
                                    <Typography variant='h6' style={{ fontWeight: '', marginLeft: 50, marginTop: 50 }}>ข้อมูลสรุปผู้ที่ไม่ผ่านเกณฑ์  Prescreen</Typography>
                                </Grid>

                                <div style={{ height: 270, width: "100%", marginTop: 20, paddingLeft: 50, paddingRight: 50 }}>
                                    <DataGrid
                                        rows={rows_1}
                                        columns={columns1}
                                        disableColumnMenu
                                        hideFooterPagination
                                        hideFooterSelectedRowCount
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 3,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[3]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                textOverflow: "clip",
                                                whiteSpace: "break-spaces",
                                                lineHeight: 1.5,
                                            },
                                        }}
                                    />
                                </div>
                                <Grid item xs>
                                    <Typography variant='h6' style={{ fontWeight: '', marginLeft: 50, marginTop: 50 }}>ข้อมูลสรุปผู้ที่ผ่านเกณฑ์  Prescreen</Typography>
                                </Grid>

                                <div style={{ height: 270, width: "100%", marginTop: 20, paddingLeft: 50, paddingRight: 50 }}>
                                    <DataGrid
                                        rows={rows_2}
                                        columns={columns2}
                                        disableColumnMenu
                                        hideFooterPagination
                                        hideFooterSelectedRowCount
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                textOverflow: "clip",
                                                whiteSpace: "break-spaces",
                                                lineHeight: 1.5,
                                            },
                                        }}
                                    />
                                </div>
                                <Grid item xs>
                                    <Typography variant='h6' style={{ fontWeight: '', marginLeft: 50, marginTop: 50 }}>ข้อมูลสรุปผู้ที่ผ่านเกณฑ์ Prescreen แต่ยังไม่ได้สมัคร DigitalApp</Typography>
                                </Grid>

                                <div style={{ height: 270, width: "100%", marginTop: 20, paddingLeft: 50, paddingRight: 50 }}>
                                    <DataGrid
                                        rows={rows_3}
                                        columns={columns3}
                                        disableColumnMenu
                                        hideFooterPagination
                                        hideFooterSelectedRowCount
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                textOverflow: "clip",
                                                whiteSpace: "break-spaces",
                                                lineHeight: 1.5,
                                            },
                                        }}
                                    />
                                </div>
                            </Box>
                        )}

                    </Paper>
                    <Dialog open={open_popup} onClose={() => setopenPopup(false)} fullWidth
                        maxWidth="md">
                        <DialogTitle>รายชื่อข้อมูลผู้ที่ผ่าน Prescreen แต่ยังไม่ได้สมัคร Digital App</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    height: 630,
                                    width: "100%",
                                }}
                            >
                                <DataGrid
                                    rows={user_list}
                                    columns={columns_user}
                                    disableRowSelectionOnClick
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    sx={{
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1.5,
                                        },
                                    }}
                                />
                                {/* {open && ( // Render loading indicator when open is true
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )} */}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setopenPopup(false)} color="primary">
                                ปิด
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </>
    )
}