import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as userHttpServices from '../../services/login_service';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import logo from '../../../src/img/TFM_sign2.png';
import InputLabel from '@mui/material/InputLabel';
import jwt_decode from "jwt-decode";
const theme = createTheme();

export default function SignIn() {
    localStorage.clear();
    const [org, setOrg] = React.useState("");
    const [org_name, setOrgName] = React.useState("");

    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')

    let access_token
    let org_list = [{ org_id: 1, org_name: "PICO" }, { org_id: 2, org_name: "MSCW" }]
    const handleChange = (event) => {
        if (event.target.value == 1) {
            setOrgName("PICO");

        } else if (event.target.value == 2) {
            setOrgName("MSCW");
        }
        setOrg(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        let object = {
            "org_id": org,
            "username": username,
            "password": password
        }
        userHttpServices.TFMLogin(object)
            .then((res) => {

                if (res.data.response.status) {
                    access_token = jwt_decode(res.data.data[0].accessToken)
                    let object

                    if (access_token.old_user_id === null) {
                        object = {
                            "Username": 'admin',
                            "Password": "password",
                            "user_id": 1,
                            "org_id": 2
                        }
                    } else {
                        object = {
                            "Username": username,
                            "Password": password,
                            "user_id": access_token.old_user_id,
                            "org_id": 2
                        }

                    }

                    userHttpServices.LoginOrg(object)
                        .then((res) => {
                            let token_org = res.data.data[0].accessToken
                            let role = res.data.data[0].role_id
                            localStorage.setItem('token_org', token_org);
                            let permiss = access_token.permis_list.find(permis => permis.permis_value === 1);
                            let first_mnu = access_token.menu_list.find(menu => menu.menu_id === permiss.sub_menu_id && menu.access === 1);

                            window.location.href = first_mnu.menu_uri
                        })

                    localStorage.setItem('org_name', org_name)
                    localStorage.setItem('menus', JSON.stringify(access_token.menu_list))
                    localStorage.setItem('token', res.data.data[0].accessToken)
                    localStorage.setItem('permis', JSON.stringify(access_token.permis_list))
                }
            })
            .catch((err) => {
                console.log(err)
                if (org === "") {
                    alert('Please Select Organization')
                } else {
                    alert(' Username OR Password is incorrect  OR Change Organization')
                }
            })
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" sx={{ width: '500px' }} style={{ marginTop: '8rem' }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    <img fullwidth="true" src={logo} alt='' />
                    <Typography component="h1" variant="h5" marginLeft={15}>
                        <i>Back Office</i>
                    </Typography>
                    <Typography component="h1" variant="h5" marginTop={2}>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormControl sx={{ mt: 3, minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Organization</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="select-org"
                                name="select-org"
                                value={org}
                                onChange={handleChange}
                                label="Organization"
                            >
                                {org_list.map((list) =>
                                    <MenuItem key={list.org_id} value={list.org_id}>{list.org_name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <br />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            id="button_login"
                            name="button_login"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}