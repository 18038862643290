import axios from "axios";

const config = require('../config.js')

const LOGIN_URL = config.LOGIN_URL
const ROOT_URL = config.DTA_API
// const ROOT_URL = 'http://localhost:4444'
// const LOGIN_URL = 'http://localhost:9001'

export const TFMLogin = (body) => {
    return axios.post(`${LOGIN_URL}/authen/login`, body, {
    })
}
export const LoginOrg = (body) => {
    return axios.post(`https://collector-api.nova.tfm.in.th/generateToken`, body, {
    })
}
export const checkBrandUser = (object, callback) => {
    return axios.post(`${ROOT_URL}/user/check_brand`, object ,{
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
export const getAllBrandUser = () => {
    return axios.get(`${ROOT_URL}/user/brand_all_user`,{
        headers: {
            'Content-Type': 'application/json',
        },
    })
}