import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import * as DirectDebit from '../../services/direct_debit.js';
import * as userHttpServices from './../../../src/services/services';
export default function DirectDebitReport() {
    let token = localStorage.getItem('token')
    let org_name = localStorage.getItem('org_name')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
        // console.log(decoded);
    }
    const location = useLocation();
    const has_permis = checkPermission(location.pathname, decoded);

    const [start_report, setStartReport] = React.useState(new Date());
    const [end_report, setEndReport] = React.useState(new Date());
    const [report_data, setReportData] = React.useState([]);
    const [report_hide_collector_data, setReportHideCollectorData] = React.useState([]);
    const [report_show_collector_data, setReportShowCollectorData] = React.useState([]);
    const [is_report_data_ready, setIsReportDataReady] = React.useState(true);
    const [mode, setMode] = React.useState("show");

    const onGetReport = async () => {
        if (start_report >= end_report) {
            alert("Error")
            setIsReportDataReady(true)
        } else {

            setIsReportDataReady(false)
            let obj = {
                start_date: start_report.$y + "-" + (start_report.$M + 1) + "-" + start_report.$D,
                end_date: end_report.$y + "-" + (1 + end_report.$M) + "-" + end_report.$D,
            }
            // console.log(obj);
            let total_amount_report = await DirectDebit.getTotalAmount(obj)
            let success_amount_report = await DirectDebit.getSuccessAmount(obj)
            let unsuccess_amount_report = await DirectDebit.getUnsuccessAmount(obj)
            let token = localStorage.getItem('token_org')
            let policy_data = await userHttpServices.getManagementReport(token)
            // console.log(total_amount_report.data.data);
            // console.log(success_amount_report.data.data);
            // console.log(unsuccess_amount_report.data.data);
            console.log(JSON.stringify(total_amount_report.data.data));
            console.log(JSON.stringify(success_amount_report.data.data))
            console.log(JSON.stringify(unsuccess_amount_report.data.data))
            console.log(JSON.stringify(policy_data.data.data))
            let index = 0
            let temp_list = []
            // temp_list['all'] = {
            //     'num_total': 0,
            //     'num_success': 0,
            //     'num_unsuccess': 0,
            //     'amount_total': 0,
            //     'amount_success': 0,
            // }
            // console.log(total_amount_report);
            if (total_amount_report.data.data === undefined) {
                alert("ไม่พบข้อมูลการรับเงิน กรุณาเปลี่ยนวัน")
                setIsReportDataReady(true)
            } else {

                for (let data of total_amount_report.data.data) {
                    if (data.contract_no !== null) {
                        const pos_data = policy_data.data.data.find(
                            (dp) => dp.Policy_no === data.contract_no
                        );
                        if (pos_data === undefined) {
                            continue
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup] === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup] = []
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup]['all_collector']  === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'] = {
                                'group': pos_data.PolicyGroup,
                                'collector': "ติดตามหนี้ทั้งหมด",
                                'num_total': 1,
                                'num_success': 0,
                                'num_unsuccess': 0,
                                'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        }else{
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'].num_total += 1
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'].amount_total += parseFloat(data.amount)
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name] === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name] = {
                                'group': pos_data.PolicyGroup,
                                'collector': pos_data.Name,
                                'num_total': 1,
                                'num_success': 0,
                                'num_unsuccess': 0,
                                'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        } else {
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name].num_total += 1
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name].amount_total += parseFloat(data.amount)
                        }
                        if (temp_list['group_all' ] === undefined) {
                            temp_list['group_all'] = []
                        }
                        if (temp_list['group_all']['all_collector']  === undefined) {
                            temp_list['group_all']['all_collector'] = {
                                'group': pos_data.PolicyGroup,
                                'collector': "ติดตามหนี้ทั้งหมด",
                                'num_total': 1,
                                'num_success': 0,
                                'num_unsuccess': 0,
                                'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        }else{

                            temp_list['group_all'] ['all_collector'].num_total += 1
                            temp_list['group_all'] ['all_collector'].amount_total += parseFloat(data.amount)
                        }
                        // console.log(temp_list);
                        if (temp_list['group_all']['collector_' + pos_data.Name] === undefined) {
                            temp_list['group_all']['collector_' + pos_data.Name] = {
                                'group': 'ลูกหนี้ทั้งหมด',
                                'collector': pos_data.Name,
                                'num_total': 1,
                                'num_success': 0,
                                'num_unsuccess': 0,
                                'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        } else {
                            temp_list['group_all']['collector_' + pos_data.Name].num_total += 1
                            temp_list['group_all']['collector_' + pos_data.Name].amount_total += parseFloat(data.amount)
                        }
                    }
                }

                for (let data of success_amount_report.data.data) {
                    if (data.contract_no !== null) {
                        const pos_data = policy_data.data.data.find(
                            (dp) => dp.Policy_no === data.contract_no
                        );
                        if (pos_data === undefined) {
                            continue
                        }
                        // console.log(data);
                        // console.log(pos_data);
                        // temp_list.all.num_total += 1
                        // temp_list.all.amount_total += parseFloat(data.amount)

                        if (temp_list['group_' + pos_data.PolicyGroup] === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup] = []
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup]['all_collector']  === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'] = {
                                'group': pos_data.PolicyGroup,
                                'collector': "ติดตามหนี้ทั้งหมด",
                                'num_total': 0,
                                'num_success': 1,
                                'num_unsuccess': 0,
                                'amount_success': parseFloat(data.amount),
                                'amount_total': 0,
                            }
                        }else{
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'].num_success += 1
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'].amount_success += parseFloat(data.amount)
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name] === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name] = {
                                'group': pos_data.PolicyGroup,
                                'collector': pos_data.Name,
                                'num_total': 0,
                                'num_success': 1,
                                'num_unsuccess': 0,
                                'amount_success': parseFloat(data.amount),
                                'amount_total': 0,
                            }
                        } else {
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name].num_success += 1
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name].amount_success += parseFloat(data.amount)
                        }

                        if (temp_list['group_all'] === undefined) {
                            temp_list['group_all'] = []
                        }
                        if (temp_list['group_all']['all_collector']  === undefined) {
                            temp_list['group_all']['all_collector'] = {
                                'group': pos_data.PolicyGroup,
                                'collector': "ติดตามหนี้ทั้งหมด",
                                'num_total': 0,
                                'num_success': 1,
                                'num_unsuccess': 0,
                                'amount_success': parseFloat(data.amount),
                                'amount_total': 0,
                            }
                        }else{
                            temp_list['group_all']['all_collector'].num_success += 1
                            temp_list['group_all']['all_collector'].amount_success += parseFloat(data.amount)
                        }
                        if (temp_list['group_all']['collector_' + pos_data.Name] === undefined) {
                            temp_list['group_all']['collector_' + pos_data.Name] = {
                                'group': 'ลูกหนี้ทั้งหมด',
                                'collector': pos_data.Name,
                                'num_total': 0,
                                'num_success': 1,
                                'num_unsuccess': 0,
                                'amount_success': parseFloat(data.amount),
                                'amount_total': 0,
                            }
                        } else {
                            temp_list['group_all']['collector_' + pos_data.Name].num_success += 1
                            temp_list['group_all']['collector_' + pos_data.Name].amount_success += parseFloat(data.amount)
                        }
                    }
                }
                
                for (let data of unsuccess_amount_report.data.data) {
                    if (data.contract_no !== null) {
                        const pos_data = policy_data.data.data.find(
                            (dp) => dp.Policy_no === data.contract_no
                        );
                        if (pos_data === undefined) {
                            continue
                        }
                        // console.log(data);
                        // console.log(pos_data);
                        // temp_list.all.num_total += 1
                        // temp_list.all.amount_total += parseFloat(data.amount)

                        if (temp_list['group_' + pos_data.PolicyGroup] === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup] = []
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup]['all_collector']  === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'] = {
                                'group': pos_data.PolicyGroup,
                                'collector': "ติดตามหนี้ทั้งหมด",
                                'num_total': 0,
                                'num_success': 0,
                                'num_unsuccess': 1,
                                // 'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        }else{
                            temp_list['group_' + pos_data.PolicyGroup]['all_collector'].num_unsuccess += 1
                            // temp_list['group_' + pos_data.PolicyGroup]['all_collector'].amount_success += parseFloat(data.amount)
                        }
                        if (temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name] === undefined) {
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name] = {
                                'group': pos_data.PolicyGroup,
                                'collector': pos_data.Name,
                                'num_total': 0,
                                'num_success': 0,
                                'num_unsuccess': 1,
                                // 'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        } else {
                            temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name].num_unsuccess += 1
                            // temp_list['group_' + pos_data.PolicyGroup]['collector_' + pos_data.Name].amount_success += parseFloat(data.amount)
                        }

                        if (temp_list['group_all'] === undefined) {
                            temp_list['group_all'] = []
                        }
                        if (temp_list['group_all']['all_collector']  === undefined) {
                            temp_list['group_all']['all_collector'] = {
                                'group': pos_data.PolicyGroup,
                                'collector': "ติดตามหนี้ทั้งหมด",
                                'num_total': 0,
                                'num_success': 0,
                                'num_unsuccess': 1,
                                // 'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        }else{
                            temp_list['group_all']['all_collector'].num_unsuccess += 1
                            // temp_list['group_' + pos_data.PolicyGroup]['all_collector'].amount_success += parseFloat(data.amount)
                        }
                        if (temp_list['group_all']['collector_' + pos_data.Name] === undefined) {
                            temp_list['group_all']['collector_' + pos_data.Name] = {
                                'group': 'ลูกหนี้ทั้งหมด',
                                'collector': pos_data.Name,
                                'num_total': 0,
                                'num_success': 0,
                                'num_unsuccess': 1,
                                // 'amount_total': parseFloat(data.amount),
                                'amount_success': 0,
                            }
                        } else {
                            temp_list['group_all']['collector_' + pos_data.Name].num_unsuccess += 1
                            // temp_list['group_all' + pos_data.PolicyGroup]['collector_' + pos_data.Name].amount_success += parseFloat(data.amount)
                        }
                    }
                }
                let table_data = []
                let table_hide_data = []
                for (let rows in temp_list) {
                    // console.log(rows);
                    // console.log(temp_list[rows]);
                    let temp_data = []
                    let temp_group = ""
                    let temp_data_hide = []
                    let temp_group_hide = ""
                    for (let cols in temp_list[rows]) {
                        if(cols !== "all_collector"){
                            temp_group = temp_list[rows][cols].group
                            temp_data.push(temp_list[rows][cols])
                        }else{
                            temp_group_hide = temp_list[rows][cols].group
                            temp_data_hide.push(temp_list[rows][cols])
                        }
                        // temp_group = temp_list[rows][cols].group
                        // temp_data.push(temp_list[rows][cols])
                    }
                    table_data.push({
                        'group': temp_group,
                        'data': temp_data
                    })
                    table_hide_data.push({
                        'group': temp_group_hide,
                        'data': temp_data_hide
                    })
                }
                let filteredArray = []
                let filteredArray2 = []

                // if (org_name === "NVCASH") {
                //     filteredArray = table_data.filter(item => item.group === "ฝากบันทึก - Nova Capital");
                //     filteredArray2 = table_hide_data.filter(item => item.group === "ฝากบันทึก - Nova Capital");


                // } else {
                    filteredArray = table_data
                // }
                setReportData(filteredArray)
                setReportShowCollectorData(filteredArray)
                setReportHideCollectorData(filteredArray2)
                setIsReportDataReady(true)
            }
        }
    }

    const handleHide = () => {
        if(mode === "show"){
            setReportData(report_hide_collector_data)
            setMode("hide")
        }else{
            setReportData(report_show_collector_data)
            setMode("show")
        }
    }

    React.useEffect(() => {
        if (!has_permis) {
            window.location.href = '/404';
        } else {

        }
    }, [])
    return (
        <>
            {has_permis && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Direct Debit Report</Typography>
                        </Grid>
                    </Grid>
                    <Paper elevation={3} >
                        {/* <Typography 
                            variant='h6' 
                            style={{ padding: '10px' }}
                        >
                            Direct Debit Report
                        </Typography> */}
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่เริ่มต้น"
                                        inputFormat="YYYY-MM-DD"
                                        value={start_report}
                                        onChange={(date) => setStartReport(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}

                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่สิ้นสุด"
                                        inputFormat="YYYY-MM-DD"
                                        value={end_report}
                                        onChange={(date) => setEndReport(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />

                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <Button style={{ float: 'right' }} variant="contained" onClick={() => onGetReport()}>ค้นหา</Button>
                            </Grid>
                            <Grid item>
                                <Button style={{ float: 'right' }} variant="contained" onClick={() => handleHide()}>ซ่อน / แสดง Collector</Button>
                            </Grid>
                        </Grid>
                        {
                            !is_report_data_ready ? 
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={!is_report_data_ready}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                             :
                                <Grid item style={{ padding: '1rem' }}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >กลุ่มสัญญา</TableCell>
                                                <TableCell colSpan={2}>collector</TableCell>
                                                <TableCell align="right">จำนวนราย</TableCell>
                                                <TableCell align="right">จำนวนเงิน</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                report_data.map(items => {
                                                    return (
                                                        <>
                                                            {
                                                                items.data.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <TableRow key={items.group+"-"+item.collector+"-"+index}>
                                                                                <TableCell rowSpan={3}>{items.group}</TableCell>
                                                                                <TableCell rowSpan={3}>{item.collector}</TableCell>
                                                                                <TableCell align="center">ทั้งหมด</TableCell>
                                                                                <TableCell align="right">{item.num_total}</TableCell>
                                                                                <TableCell align="right">{item.amount_total.toLocaleString("en-US")}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow key={items.group+"-"+item.collector+"2"+index}>
                                                                                <TableCell align="center">ตัดสำเร็จ</TableCell>
                                                                                <TableCell align="right">{item.num_success}</TableCell>
                                                                                <TableCell align="right">{item.amount_success.toLocaleString("en-US")}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow key={items.group+"-"+item.collector+"3"+index}>
                                                                                <TableCell align="center">ตัดไม่สำเร็จ</TableCell>
                                                                                <TableCell align="right">{item.num_unsuccess}</TableCell>
                                                                                <TableCell align="right">-</TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                    // items.data.map(item=>{
                                                    //             <TableRow>
                                                    //              <TableCell rowSpan={3}>{item.label}</TableCell>
                                                    //              <TableCell align="center">ทั้งหมด</TableCell>
                                                    //              <TableCell align="right">{item.num_total}</TableCell>
                                                    //              <TableCell align="right">{item.amount_total.toFixed(2)}</TableCell>
                                                    //          </TableRow>
                                                    //          <TableRow>
                                                    //              <TableCell align="center">ตัดสำเร็จ</TableCell>
                                                    //              <TableCell align="right">{item.num_success}</TableCell>
                                                    //              <TableCell align="right">{item.amount_success.toFixed(2)}</TableCell>
                                                    //          </TableRow>
                                                    //          <TableRow>
                                                    //              <TableCell align="center">ตัดไม่สำเร็จ</TableCell>
                                                    //              <TableCell align="right">{item.num_unsuccess}</TableCell>
                                                    //              <TableCell align="right">-</TableCell>
                                                    //          </TableRow>
                                                    // })
                                                    // console.log(items);
                                                    // items.map(item => {

                                                    // return (
                                                    //     <>
                                                    //         <TableRow>
                                                    //             <TableCell rowSpan={3}>{item.label}</TableCell>
                                                    //             <TableCell align="center">ทั้งหมด</TableCell>
                                                    //             <TableCell align="right">{item.num_total}</TableCell>
                                                    //             <TableCell align="right">{item.amount_total.toFixed(2)}</TableCell>
                                                    //         </TableRow>
                                                    //         <TableRow>
                                                    //             <TableCell align="center">ตัดสำเร็จ</TableCell>
                                                    //             <TableCell align="right">{item.num_success}</TableCell>
                                                    //             <TableCell align="right">{item.amount_success.toFixed(2)}</TableCell>
                                                    //         </TableRow>
                                                    //         <TableRow>
                                                    //             <TableCell align="center">ตัดไม่สำเร็จ</TableCell>
                                                    //             <TableCell align="right">{item.num_unsuccess}</TableCell>
                                                    //             <TableCell align="right">-</TableCell>
                                                    //         </TableRow>
                                                    //     </>
                                                    // )
                                                    // })
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                        }
                    </Paper>
                </div>
            )}
        </>
    )
}