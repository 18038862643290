
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import ExcelExport from '../../reports/direct_debit_excel.js'

// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as CrmService from '../../services/crm_service';
// components
import Filter from '../../components/filter_2';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
//Modal
import ModalNote from '../../components/modal/crm_edit_note';

const style_note = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,

};
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(false);
    const [rows_data, setRowsData] = React.useState([]);
    const [rows, setRow] = React.useState([]);

    const [filters, setFilters] = React.useState([])
    const [popup_note, setOpenNote] = React.useState(false)
    const [note, setNote] = React.useState([])
    const [date, setDate] = React.useState()
    const [popup_consent, setOpenConsent] = React.useState(false)
    const [excel_data, setExcelData] = React.useState([])

    const navigate = useNavigate();
    const location = useLocation();
    const has_permission = checkPermission(location.pathname, decoded);
    let org_name = localStorage.getItem('org_name')

    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()

    }, [filters])

    const fetchData = () => {
        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": filters
        }
        CrmService.crmGetUser(token, object)
            .then((res) => {
                let filteredArray =[]
                     filteredArray = res.data.data
                let data = []
                filteredArray.map((item, index) => (
                    data.push(
                        {
                            "id": index + 1,
                            ...item
                        }
                    )
                ));
                setRowsData(data);
            })
    }
    //filter
    const filter = [
        {
            property: 'register_date',
            name: 'วันที่สมัคร',
            type: "date"
        },
        {
            property: 'name',
            name: 'ชื่อ-นามสกุล',
            type: "input",
        },
        {
            property: 'citizen_id',
            name: 'เลขที่บัตรประชาชน',
            type: "input",
        },
        {
            property: 'phone_number',
            name: 'เบอร์โทรศัพท์',
            type: "input",
        },
        // {
        //     property: 'brand',
        //     name: 'Brand',
        //     type: 'select',
        //     option: ['MSCW', 'CASHKRUB', 'MOU_GSC', 'Pico Thai'],
        // },
        {
            property: 'product',
            name: 'Product',
            type: 'select',
            option: ['PLOAN', 'PLOAN-NONCB', 'PLOAN-ALL', 'PLOAN-MOU', 'NANO'],
        },
        {
            property: 'MarketingChannel',
            name: 'ช่องทางการตลาด',
            type: "input",
        },
        {
            property: 'consent_date',
            name: 'วันที่รับเอกสารconsent',
            type: "date"
        },
        {
            property: 'register_status',
            name: 'สถานะการสมัคร',
            type: 'select',
            option: ['ติดต่อไม่ได้/ไม่รับสาย', 'ยกเลิก (CM)', 'นัด Upload เอกสารเพิ่มเติม', 'นัดส่งเอกสาร consent กระดาษ', 'รอเสนอพิจารณา', 'รอพิจารณา', 'รอ Add line', 'ปฏิเสธวงเงิน', 'ยกเลิก (Credit Oper)', 'รอโอนเงิน'],
        },
        {
            property: 'approve_date',
            name: 'วันที่อนุมัติ',
            type: "date"
        },
        {
            property: 'contract_no',
            name: 'เลขที่สัญญา',
            type: "input"
        },
        {
            property: 'company_score',
            name: 'score บริษัท',
            type: "input"

        },
        {
            property: 'debt_class',
            name: 'ชั้นหนี้',
            type: "select",
            option: ['เกิน 12 เดือน', 'เกิน 6 เดือน – 12 เดือน', 'เกิน 3 เดือน – 6 เดือน', 'เกิน 1 เดือน – 3 เดือน', 'ปกติ'],

        },
        {
            property: 'dd_resister',
            name: 'สถานะเข้าระบบ DD ',
            type: "select",
            option: ['เข้าร่วม', 'ไม่เข้าร่วม'],
        }
    ];
    // columns
    const columns = [
        {
            field: '_id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'register_date',
            headerName: 'วันที่สมัคร',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'ชื่อ-นามสกุล',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'citizen_id',
            headerName: 'เลขที่บัตรประชาชน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'phone_number',
            headerName: 'เบอร์โทรศัพท์',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'brand',
            headerName: 'Brand',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'product',
            headerName: 'Product',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'MarketingChannel',
            headerName: 'ช่องทางการตลาด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'consent_date',
            headerName: 'วันที่รับเอกสารconsent',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'consent',
            headerName: '',
            width: 20,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={(event) => {
                            handleOpenEditConsent(event, cellValues);
                        }} ><EditIcon color="primary" /></IconButton>
                    </>
                );
            }

        },
        {
            field: 'register_status',
            headerName: 'สถานะการสมัคร',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'approve_date',
            headerName: 'วันที่อนุมัติ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'company_score',
            headerName: 'score บริษัท',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'debt_class',
            headerName: 'ชั้นหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'dd_resister',
            headerName: 'สถานะเข้าระบบ DD ',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'note_all_contact',
            headerName: 'Note',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'note',
            headerName: '',
            width: 20,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={(event) => {
                            handleOpenEditNote(event, cellValues);
                        }} ><EditIcon color="primary" /></IconButton>
                    </>
                );
            }

        },

        {
            field: 'Edit',
            headerName: 'ดูรายละเอียด',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (

                    <IconButton id='button-edit' onClick={(event) => {
                        handleOpenDetail(event, cellValues);
                    }}><SearchIcon color="primary" /></IconButton>
                );
            }
        },
    ];

    // function
    const handleOpenEditNote = (e, value) => {
        setRow(value.row)
        setNote(value.row.note_all_contact)
        setOpenNote(true)
    }
    const handleOpenDetail = (e, value) => {
        console.log(value)
        const parameterData = { citizen_id: value.row.citizen_id, };
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/crm/customer/detail/list?' + queryParams, '_blank');
    };

    const handleOpenEditConsent = (e, value) => {
        setRow(value.row)
        setDate(value.row.consent_date)
        setOpenConsent(true)
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleEdit = (value) => {
        let body
        if (value === 'note') {
            body = {
                query: rows._id,
                contact_id: rows.contact_id,
                user_id: rows.citizen_id,
                note: note,
                note_type: "note_all_contact"
            }
        } else if (value === 'consent') {
            body = {
                query: rows._id,
                consent_date: date,
                contact_id: rows.contact_id,
                user_id: rows.citizen_id,

            }
        }

        CrmService.crmEdit(token, body)
            .then((res) => {
                if (res.data.success) {
                    alert('บันทึกสำเร็จ')
                    setOpenConsent(false)
                    setOpenNote(false)
                    fetchData()
                }
            })
    }
    //
    const handleExportExcelButton = () => {
        const list_datas = [];
        rows_data.map(item => {
            list_datas.push({

                "วันที่สมัคร": item.register_date,
                "ชื่อ-นามสกุล ": item.name,
                "เลขที่บัตรประชาชน": item.citizen_id,
                "เบอร์โทรศัพท์": item.phone_number,
                "Brand": item.brand,
                "Product": item.product,
                "ช่องทางการตลาด": item.MarketingChannel,
                "วันที่รับเอกสารconsent": item.consent_date,
                "สถานะการสมัคร": item.register_status,
                "วันที่อนุมัติ": item.approve_date,
                "เลขที่สัญญา": item.contract_no,
                "score บริษัท": item.company_score,
                "ชั้นหนี้": item.debt_class,
                "สถานะเข้าระบบ DD": item.dd_resister,
                "Note": item.note_all_contact,


            })
        });

        setExcelData(list_datas);
        ExcelExport(list_datas, "crm_all_contact");
    }
    return (
        <>

            {has_permission && (
                <div className='customer-list' style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Filter
                            list={filter}
                            handleSetFilters={setFilters}
                        />
                        <Grid container spacing={2} style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Grid item>
                                <Button variant="contained" color="success" style={{ margin: "10px" }}
                                    onClick={() => handleExportExcelButton()}
                                > export</Button>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_data}
                                columns={columns}
                                // components={{
                                //     Toolbar: CustomToolbar,
                                // }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>

                    </Paper>
                </div>

            )}

            <Modal
                open={popup_note}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_note}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenNote(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="child-modal-title">เพิ่มหมายเหตุ</h2>
                    <TextField
                        label="หมายเหตุ"
                        multiline
                        minRows={3} 
                        maxRows={5} 
                        variant="outlined"
                        fullWidth
                        value={note} onChange={(e) => setNote(e.target.value)}
                    />
                    <br></br>
                    <Button style={{ float: 'right' }} variant="contained" onClick={(e) => handleEdit('note')}>บันทึก</Button>
                </Box>
            </Modal>
            <Modal
                open={popup_consent}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenConsent(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <h2 id="app">วันที่รับ consent</h2>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DesktopDatePicker
                            label="วันที่รับ consent"
                            inputFormat="YYYY-MM-DD"
                            value={date}
                            onChange={(date) => setDate(date)}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                    <br></br>
                    <Button style={{ float: 'right', marginTop: 10 }} variant="contained" onClick={(e) => handleEdit('consent')} >บันทึก</Button>
                </Box>

            </Modal>
        </>
    )
}
