import axios from "axios";
const config = require('../config.js')
const ROOT_URL = config.ROOT_URL
//   const ROOT_URL  = "http://localhost:54450";
export const getTackinglist = (token, object, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/list`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getTackingRefinanceList = (token, object, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/refinance/list`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getTackingListApproved = (token, object, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/list_approved`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getFileStatuslist = (token, data, callback) => {
    return axios.get(`${ROOT_URL}/file/list/` + data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getFileType = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/get_file_type`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getFile = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/get-url-file`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getForm = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/user/create_form`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const updateFileStatus = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/update/file_status`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const updateCollectorStatus = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/colloterAction`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const UpdateBrand = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/user/update_brand`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const UpdateProduct = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/user/update_product`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const approveFileContract = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/approveAction`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getReport = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/get_report`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getUserStatus = (token, data, callback) => {
    return axios.post(`${ROOT_URL}/file_tracking/get_user_report`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getMarketingDashBoard = (data, token, callback) => {
    return axios.post(`${ROOT_URL}/marketing/Dashboard`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getMarketingDashBoardUserList = (data, token, callback) => {
    return axios.post(`${ROOT_URL}/marketing/Dashboard_detail`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const getMarketingtracking = (data, token, callback) => {
    return axios.get(`${ROOT_URL}/marketing/tracking`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
export const MarketingtrackingUpdate = (data, token, callback) => {
    return axios.post(`${ROOT_URL}/marketing/tracking_update`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}


